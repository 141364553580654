/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateTeamInput,
  SportTypesResponse,
  TeamModel,
  TeamsState,
} from '../../common/types';

const initialState: TeamsState = {
  loading: false,
  success: null,
  errors: [],
  teams: null,
  addTeam: {
    loading: false,
    success: null,
    errors: [],
  },
  deleteTeam: {
    loading: false,
    success: null,
    errors: [],
  },
  loadTeam: {
    loading: false,
    success: null,
    errors: [],
  },
  sportTypes: null,
  loadSportTypes: {
    loading: false,
    success: null,
    errors: [],
  },
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadTeams(state, action: PayloadAction<string>) {
      state.loading = true;
      state.errors = [];
    },
    loadTeamsSuccess(state, action: PayloadAction<TeamModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.teams = action.payload;
    },
    loadTeamsError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadTeams(state) {
      state.loadTeam.success = null;
      state.loadTeam.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addTeam(state, action: PayloadAction<CreateTeamInput>) {
      state.addTeam.loading = true;
      state.addTeam.errors = [];
    },
    addTeamSuccess(state, action: PayloadAction<boolean>) {
      state.addTeam.loading = false;
      state.addTeam.success = action.payload;
    },
    addTeamError(state, action: PayloadAction<string[]>) {
      state.addTeam.loading = false;
      state.addTeam.errors = action.payload;
    },
    resetAddTeamSuccess(state) {
      state.addTeam.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadTeam(state, action: PayloadAction<string>) {
      state.loadTeam.loading = true;
      state.loadTeam.errors = [];
    },
    loadTeamSuccess(state, action: PayloadAction<TeamModel>) {
      state.loadTeam.loading = false;
      state.loadTeam.success = action.payload;
    },
    loadTeamError(state, action: PayloadAction<string[]>) {
      state.loadTeam.loading = false;
      state.loadTeam.errors = action.payload;
    },
    resetLoadTeam(state) {
      state.loadTeam.success = null;
      state.loadTeam.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadSportTypes(state, action: PayloadAction<undefined>) {
      state.loadSportTypes.loading = true;
      state.loadSportTypes.errors = [];
    },
    loadSportTypesSuccess(state, action: PayloadAction<SportTypesResponse>) {
      console.log(action.payload);
      state.loadSportTypes.loading = false;
      state.loadSportTypes.success = !!action.payload;
      state.sportTypes = action.payload.sportTypes;
    },
    loadSportTypesError(state, action: PayloadAction<string[]>) {
      state.loadSportTypes.loading = false;
      state.loadSportTypes.errors = action.payload;
    },
    resetLoadSportTypes(state) {
      state.loadSportTypes.success = null;
      state.loadSportTypes.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteTeam(state, action: PayloadAction<string>) {
      state.deleteTeam.loading = true;
      state.deleteTeam.errors = [];
    },
    deleteTeamSuccess(state, action: PayloadAction<boolean>) {
      state.deleteTeam.loading = false;
      state.deleteTeam.success = action.payload;
    },
    deleteTeamError(state, action: PayloadAction<string[]>) {
      state.deleteTeam.loading = false;
      state.deleteTeam.errors = action.payload;
    },
    resetDeleteTeamSuccess(state) {
      state.deleteTeam.success = null;
    },
  },
});

export const {
  addTeam,
  addTeamSuccess,
  addTeamError,
  resetAddTeamSuccess,
  loadTeam,
  loadTeamSuccess,
  loadTeamError,
  resetLoadTeam,
  loadTeams,
  loadTeamsSuccess,
  loadTeamsError,
  resetLoadTeams,
  loadSportTypes,
  loadSportTypesSuccess,
  loadSportTypesError,
  resetLoadSportTypes,
  deleteTeam,
  deleteTeamError,
  deleteTeamSuccess,
  resetDeleteTeamSuccess,
} = teamsSlice.actions;

export const { name: teamsSliceName, reducer: teamsSliceReducer } = teamsSlice;
