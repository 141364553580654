/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import ApiService from '../../services/api';
import {
  addSport,
  addSportError,
  addSportSuccess,
  deleteSport,
  deleteSportError,
  deleteSportSuccess,
  loadSport,
  loadSportError,
  loadSports,
  loadSportsError,
  loadSportsSuccess,
  loadSportSuccess,
} from './sports.slice';

export function* addSportAsync(action: PayloadAction<any>) {
  const { data, errors } = yield call(ApiService.addSport, action.payload);

  if (errors) {
    yield put(addSportError(errors));
  } else {
    yield put(addSportSuccess(!!data));
  }
}

export function* deleteSportAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.deleteSport, action.payload);

  if (!data) {
    yield put(deleteSportError(['404']));
  } else {
    yield put(deleteSportSuccess(data));
  }
}

export function* loadSportAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadSportFormDetails, action.payload);

  if (!data) {
    yield put(loadSportError(['404']));
  } else {
    yield put(loadSportSuccess(data));
  }
}

export function* loadSportsAsync() {
  const { data } = yield call(ApiService.getSportsForTable);

  if (!data) {
    yield put(loadSportsError(['500']));
  } else {
    yield put(loadSportsSuccess(data));
  }
}

export function* watchSportsAsync() {
  yield takeEvery(addSport.toString(), addSportAsync);
  yield takeEvery(deleteSport.toString(), deleteSportAsync);
  yield takeEvery(loadSport.toString(), loadSportAsync);
  yield takeEvery(loadSports.toString(), loadSportsAsync);
}
