import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppRoutePath, PublicRoutePath } from '../common/constants';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import DashboardPage from '../features/dashboard/DashboardPage';
import GamesPage from '../features/games/GamesPage';
import LoginPage from '../features/login/LoginPage';
import PoolsPage from '../features/pools/PoolsPage';
import SportsPage from '../features/sports/SportsPage';
import TeamsPage from '../features/teams/TeamsPage';
import history from './history';

function RootRoutes(): JSX.Element {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PublicRoute path={PublicRoutePath.Login}>
          <LoginPage />
        </PublicRoute>
        <PrivateRoute path={AppRoutePath.Dashboard}>
          <DashboardPage />
        </PrivateRoute>
        <Route path={AppRoutePath.Sports}>
          <SportsPage />
        </Route>
        <Route path={AppRoutePath.Teams}>
          <TeamsPage />
        </Route>
        <Route path={AppRoutePath.Pools}>
          <PoolsPage />
        </Route>
        <Route path={AppRoutePath.Games}>
          <GamesPage />
        </Route>
        <PublicRoute path="/">
          <LoginPage />
        </PublicRoute>
      </Switch>
    </ConnectedRouter>
  );
}

export default RootRoutes;
