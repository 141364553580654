/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { MenuItemModel } from '../../common/types';

type SideMenuProps = {
  menuItems: MenuItemModel[];
  pathname: string;
};

const SideMenu: React.FC<SideMenuProps> = ({
  menuItems,
  pathname,
}: SideMenuProps): JSX.Element => {
  const links = menuItems.map(({ children, ...rest }) => {
    return {
      ...rest,
      children: children || [],
    };
  });

  const [activeDropDown, setActiveDropDown] = useState<string>();

  function getClassNameForParent(path: string, hasChildren = false) {
    if (hasChildren) {
      let className = 'menu-item-has-children dropdown';

      const hasActiveRoute = pathname.startsWith(path);

      if (hasActiveRoute) {
        className += ' active';
      }

      if (activeDropDown === path) {
        className += ' show';
      }

      return className;
    }

    return path === pathname ? 'active' : '';
  }

  return (
    <>
      <div className="left-menubar left-menubar-horizontal d-block d-lg-none">
        <div className="page-wrapper">
          <nav id="sidebar" className="sidebar-wrapper mx-auto">
            <div className="sidebar-content d-flex justify-content-center">
              <div className="sidebar-menu">
                <ul>
                  {links.map(({ path, title, icon, children }) => {
                    return children && children.length > 0 ? (
                      <li
                        key={path}
                        className={getClassNameForParent(path, true)}
                      >
                        <Link
                          to={path}
                          className="dropdown-toggle"
                          onClick={(e) => {
                            e.preventDefault();

                            if (activeDropDown === path) {
                              setActiveDropDown(undefined);
                            } else {
                              setActiveDropDown(path);
                            }
                          }}
                        >
                          <i className={`menu-icon fa ${icon}`} />
                          <span>{title}</span>
                        </Link>
                        <ul
                          className={`sub-menu children dropdown-menu ${
                            activeDropDown === path ? 'show' : ''
                          }`}
                        >
                          <li className="subtitle">
                            <i className={`menu-icon fa ${icon}`} />
                            <span>{title}</span>
                          </li>
                          {children.map(
                            ({
                              path: childPath,
                              title: childTitle,
                              icon: childIcon,
                            }) => {
                              return (
                                <li key={childPath}>
                                  <Link
                                    to={childPath}
                                    className="dropdown-toggle"
                                  >
                                    <i
                                      className={`menu-icon fa ${childIcon}`}
                                    />
                                    <span>{childTitle}</span>
                                  </Link>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </li>
                    ) : (
                      <li key={path} className={getClassNameForParent(path)}>
                        <Link to={path}>
                          <i className={`menu-icon fa ${icon}`} />
                          {title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="left-menubar d-lg-block d-none">
        <div className="page-wrapper">
          <nav id="sidebar" className="sidebar-wrapper">
            <div className="sidebar-brand">
              <span>&nbsp;</span>
            </div>
            <div className="sidebar-content">
              <div className="sidebar-menu">
                <ul>
                  {links.map(({ path, title, icon, children }) => {
                    return children && children.length > 0 ? (
                      <li
                        key={path}
                        className={getClassNameForParent(path, true)}
                      >
                        <Link
                          to={path}
                          className="dropdown-toggle"
                          onClick={(e) => {
                            e.preventDefault();

                            if (activeDropDown === path) {
                              setActiveDropDown(undefined);
                            } else {
                              setActiveDropDown(path);
                            }
                          }}
                        >
                          <i className={`menu-icon fa ${icon}`} />
                          <span>{title}</span>
                        </Link>
                        <ul
                          className={`sub-menu children dropdown-menu ${
                            activeDropDown === path ? 'show' : ''
                          }`}
                        >
                          <li className="subtitle">
                            <i className={`menu-icon fa ${icon}`} />
                            <span>{title}</span>
                          </li>
                          {children.map(
                            ({
                              path: childPath,
                              title: childTitle,
                              icon: childIcon,
                            }) => {
                              return (
                                <li key={childPath}>
                                  <Link
                                    to={childPath}
                                    className="dropdown-toggle"
                                  >
                                    <i
                                      className={`menu-icon fa ${childIcon}`}
                                    />
                                    <span>{childTitle}</span>
                                  </Link>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </li>
                    ) : (
                      <li key={path} className={getClassNameForParent(path)}>
                        <Link to={path}>
                          <i className={`menu-icon fa ${icon}`} />
                          {title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
