/* eslint-disable jsx-a11y/label-has-associated-control */

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreateGameInput, GameModel, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import GameForm, { CreateGameProps } from './GameForm';
import {
  loadGame,
  loadSportTypes,
  loadTeams,
  resetLoadGame,
  resetLoadSportTypes,
  resetLoadTeams,
} from './games.slice';

type GameRecordPageParams = {
  onSubmit: (data: CreateGameInput) => void;
  onPublish: (id: string) => void;
  onCancel: (id: string) => void;
  onDelete: (id: string) => void;
  errors: string[];
  loading: boolean;
};

const GameRecordPage = ({
  onSubmit: onSubmitParent,
  onPublish: onPublishParent,
  onCancel: onCancelParent,
  onDelete: onDeleteParent,
  errors,
  loading,
}: GameRecordPageParams): JSX.Element => {
  const { gameId } = useParams<{ gameId: string }>();
  const { games: gamesTxt, app: appTxt } = LanguageTexts;

  const [gameLoaded, setGameLoaded] = useState<boolean>();
  const [games, setGames] = useState<GameModel>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadGame: {
      loading: loadGameLoading,
      errors: loadGameErrors,
      success: loadGameSuccess,
    },
    loadSportTypes: {
      loading: loadSportTypesLoading,
      errors: loadSportTypesErrors,
      success: loadSportTypesSuccess,
    },
    loadTeams: {
      loading: loadTeamsLoading,
      errors: loadTeamsErrors,
      success: loadTeamsSuccess,
    },
    sportTypes,
    teams,
  } = useSelector((state: RootState) => state.games);

  const { user } = useSelector((state: RootState) => state.login);

  const [sportTypesLoadRequested, setSportTypesRequested] = useState<boolean>();

  useEffect(() => {
    if (gameId && !gameLoaded) {
      dispatch(loadGame(gameId));
      setGameLoaded(true);
    }
  }, [gameLoaded, gameId, dispatch]);

  useEffect(() => {
    if (loadGameErrors.length > 0) {
      dispatch(resetLoadGame());
      history.push(AppRoutePath.Games);
    } else if (loadGameSuccess) {
      setGames(loadGameSuccess);
      dispatch(loadTeams(loadGameSuccess.sportTypeId));
      dispatch(resetLoadGame());
    }
  }, [loadGameErrors, loadGameSuccess, dispatch, history]);

  // load sport types
  useEffect(() => {
    if (!sportTypesLoadRequested) {
      dispatch(loadSportTypes());
      setSportTypesRequested(true);
    }
  }, [sportTypesLoadRequested, dispatch]);

  useEffect(() => {
    if (loadSportTypesErrors.length > 0) {
      dispatch(resetLoadSportTypes());
      history.push(AppRoutePath.Games);
    } else if (loadSportTypesSuccess) {
      dispatch(resetLoadSportTypes());
    }
  }, [loadSportTypesErrors, loadSportTypesSuccess, dispatch, history]);

  useEffect(() => {
    if (loadTeamsErrors.length > 0) {
      dispatch(resetLoadTeams());
      history.push(AppRoutePath.Games);
    } else if (loadTeamsSuccess) {
      dispatch(resetLoadTeams());
    }
  }, [loadTeamsErrors, loadTeamsSuccess, dispatch, history]);

  function onSubmit({ date, time, ...restInput }: CreateGameProps) {
    const gameTime = time ? time.format('HH:mm:ss') : '';
    onSubmitParent({
      date: date ? `${date.format('YYYY-MM-DD')} ${gameTime}` : '',
      ...restInput,
    });
  }

  function onSportChange(id: any) {
    dispatch(loadTeams(id));
  }

  function onPublish(id: any) {
    onPublishParent(id);
  }

  function onDelete(id: any) {
    onDeleteParent(id);
  }

  function onCancel(id: any) {
    onCancelParent(id);
  }

  if (gameId && (!gameLoaded || loadGameLoading)) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let gameInfo: CreateGameProps | any;

  if (games) {
    const { date, ...restPools } = games;

    gameInfo = {
      ...restPools,
      date: moment(date),
      time: moment(date),
    };
  }

  const gameInfoInit = gameInfo || {
    sportTypeId: '',
    homeTeamId: '',
    awayTeamId: '',
    date: undefined,
    time: undefined,
    status: 'Scheduled',
    homeTeamScore: null,
    awayTeamScore: null,
    bet: {
      homeMoneyLine: null,
      awayMoneyLine: null,
      homePointSpread: null,
      awayPointSpread: null,
      homePointSpreadPayout: -110,
      awayPointSpreadPayout: -110,
      overUnder: null,
      overPayout: -110,
      underPayout: -110,
    },
  };

  return (
    <section>
      <h1 className="primary-heading mb-4">
        {gameId ? gamesTxt.updateGame : gamesTxt.newGame}
      </h1>

      <p className="invalid-feedback-msg text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <GameForm
          onSubmit={onSubmit}
          onPublish={onPublish}
          onCancel={onCancel}
          onDelete={onDelete}
          onSportChange={onSportChange}
          sportTypes={sportTypes || []}
          teams={teams || []}
          user={user}
          initialValues={gameInfoInit}
          errors={errors}
          loading={loading}
        />
      </div>
    </section>
  );
};

export default GameRecordPage;
