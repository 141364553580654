/* eslint-disable no-nested-ternary */
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import LanguageTexts from '../../common/language';
import { BetModel, SportTypeModel, UserModel } from '../../common/types';
import GameDatePicker from './components/GameDatePicker';
import GameTimePicker from './components/GameTimePicker';

export type CreateGameProps = {
  _id?: string;
  sportTypeId: string;
  homeTeamId: string;
  awayTeamId: string;
  date: moment.Moment | undefined;
  time: moment.Moment | undefined;
  status: string;
  isPublished: boolean;
  isCancelled: boolean;
  homeTeamScore: string;
  awayTeamScore: string;
  bet: BetModel;
};

type GameProps = {
  onSubmit: (data: CreateGameProps) => void;
  onPublish: (id: any) => void;
  onCancel: (id: any) => void;
  onDelete: (id: any) => void;
  onSportChange: (id: any) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreateGameProps;
  sportTypes: SportTypeModel[];
  teams: any[];
  user: UserModel | null;
};

const GameForm: React.FC<GameProps> = ({
  onSubmit,
  onPublish,
  onCancel,
  onDelete,
  onSportChange,
  loading,
  errors,
  initialValues,
  sportTypes,
  teams,
  user,
}: GameProps): JSX.Element => {
  const { games: gamesTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const { ...initVals } = initialValues;
  const isScoreOut =
    initVals.homeTeamScore !== null && initVals.awayTeamScore !== null;

  const [sportTypeOptions, setSportTypeOptions] = useState(
    sportTypes.map((sportType) => {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _id,
        name,
      } = sportType;
      return {
        value: _id,
        label: `${name}`,
        sportTypeObj: sportType,
      };
    }),
  );

  const [selectedSportType, setSelectedSportType] = useState(
    sportTypeOptions.find(({ value }) => value === initialValues.sportTypeId),
  );

  const [homeTeamOptions, setHomeTeamOptions] = useState(teams);

  const [selectedHomeTeam, setSelectedHomeTeam] = useState(
    homeTeamOptions.find(({ value }) => value === initialValues.homeTeamId),
  );

  const [awayTeamOptions, setAwayTeamOptions] = useState(teams);

  const [selectedAwayTeam, setSelectedAwayTeam] = useState(
    awayTeamOptions.find(({ value }) => value === initialValues.awayTeamId),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSportTypeChange(val: any) {
    setSelectedSportType(val);
    setSelectedHomeTeam(null);
    setSelectedAwayTeam(null);
    onSportChange(val.value);
  }

  useEffect(() => {
    if (teams) {
      const teamList = teams.map((team) => {
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _id,
          name,
        } = team;
        return {
          value: _id,
          label: `${name}`,
          homeTeamObj: team,
        };
      });

      const awayTeamList = teams.map((team) => {
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _id,
          name,
        } = team;
        return {
          value: _id,
          label: `${name}`,
          awayTeamObj: team,
        };
      });

      setHomeTeamOptions(teamList);
      setAwayTeamOptions(awayTeamList);
      if (initVals._id) {
        setSelectedHomeTeam(
          teamList.find(({ value }) => value === initialValues.homeTeamId),
        );
        setSelectedAwayTeam(
          awayTeamList.find(({ value }) => value === initialValues.awayTeamId),
        );
      }
    }
  }, [teams, initVals._id, initialValues.homeTeamId, initialValues.awayTeamId]);

  useEffect(() => {
    if (sportTypes) {
      const sportTypeList = sportTypes.map((sportType) => {
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _id,
          name,
        } = sportType;
        return {
          value: _id,
          label: `${name}`,
          sportTypeObj: sportType,
        };
      });

      setSportTypeOptions(sportTypeList);
      if (initVals._id) {
        setSelectedSportType(
          sportTypeList.find(
            ({ value }) => value === initialValues.sportTypeId,
          ),
        );
      }
    }
  }, [sportTypes, initVals._id, initialValues.sportTypeId]);

  function onHomeTeamChange(val: any) {
    const options: any = teams.map((team) => {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _id,
        name,
      } = team;
      return {
        value: _id,
        label: `${name}`,
        awayTeamObj: team,
      };
    });
    setAwayTeamOptions(
      options.filter((team: any) => {
        return team.value !== val.value;
      }),
    );
    setSelectedHomeTeam(val);
  }

  function onAwayTeamChange(val: any) {
    const options: any = teams.map((team) => {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _id,
        name,
      } = team;
      return {
        value: _id,
        label: `${name}`,
        homeTeamObj: team,
      };
    });
    setHomeTeamOptions(
      options.filter((team: any) => {
        return team.value !== val.value;
      }),
    );
    setSelectedAwayTeam(val);
  }

  const validate = (values: any) => {
    const errorsIn: any = {};
    errorsIn.bet = {};

    if (!selectedSportType) {
      errorsIn.sportTypeId = gamesTxt.sportTypeErrorText;
    }

    if (!selectedHomeTeam) {
      errorsIn.homeTeamId = gamesTxt.homeTeamErrorText;
    }

    if (!selectedAwayTeam) {
      errorsIn.awayTeamId = gamesTxt.awayTeamErrorText;
    }

    if (!values.date) {
      errorsIn.date = gamesTxt.dateErrorText;
    }

    if (!values.time) {
      errorsIn.time = gamesTxt.timeErrorText;
    }

    if (!values.bet.homeMoneyLine) {
      errorsIn.bet.homeMoneyLine = gamesTxt.homeMoneyLineErrorText;
    }

    if (!values.bet.awayMoneyLine) {
      errorsIn.bet.awayMoneyLine = gamesTxt.awayMoneyLineErrorText;
    }

    if (!values.bet.homePointSpread) {
      errorsIn.bet.homePointSpread = gamesTxt.homePointSpreadErrorText;
    }

    if (!values.bet.awayPointSpread) {
      errorsIn.bet.awayPointSpread = gamesTxt.awayPointSpreadErrorText;
    }

    if (!values.bet.homePointSpreadPayout) {
      errorsIn.bet.homePointSpreadPayout =
        gamesTxt.homePointSpreadPayoutErrorText;
    }

    if (!values.bet.awayPointSpreadPayout) {
      errorsIn.bet.awayPointSpreadPayout =
        gamesTxt.awayPointSpreadPayoutErrorText;
    }

    if (!values.bet.overPayout) {
      errorsIn.bet.overPayout = gamesTxt.overPayoutErrorText;
    }

    if (!values.bet.underPayout) {
      errorsIn.bet.underPayout = gamesTxt.underPayoutErrorText;
    }

    if (!values.bet.overUnder) {
      errorsIn.bet.overUnder = gamesTxt.overUnderErrorText;
    }

    if (_.isEmpty(errorsIn.bet)) {
      delete errorsIn.bet;
    }
    return errorsIn;
  };

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };
        input.sportTypeId = selectedSportType?.sportTypeObj?._id || '';
        input.homeTeamId = selectedHomeTeam?.homeTeamObj?._id || '';
        input.awayTeamId = selectedAwayTeam?.awayTeamObj?._id || '';

        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, errors: errorsForm, touched }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="sportType">
                    {gamesTxt['input.sportType']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Select
                    value={selectedSportType}
                    options={sportTypeOptions}
                    onChange={onSportTypeChange}
                    isDisabled={values.isPublished || values.isCancelled}
                  />
                  {errorsForm.sportTypeId && touched.sportTypeId && (
                    <div className="invalid-feedback-msg">
                      {errorsForm.sportTypeId}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="date">
                        {gamesTxt['input.date']}
                        <sup className="invalid-feedback-msg">
                          &nbsp;
                          <span className="fa fa-star-of-life" />
                        </sup>
                      </label>
                      <GameDatePicker
                        value={values.date}
                        disabled={values.isPublished || values.isCancelled}
                        onChange={(newValue) => {
                          setFieldValue('date', newValue);
                        }}
                      />
                      {errorsForm.date && touched.date && (
                        <div className="invalid-feedback-msg">
                          {errorsForm.date}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="time">
                        {gamesTxt['input.time']}
                        <sup className="invalid-feedback-msg">
                          &nbsp;
                          <span className="fa fa-star-of-life" />
                        </sup>
                      </label>
                      <GameTimePicker
                        value={values.time}
                        disabled={values.isPublished || values.isCancelled}
                        onChange={(newVal) => setFieldValue('time', newVal)}
                      />
                      {errorsForm.time && touched.time && (
                        <div className="invalid-feedback-msg">
                          {errorsForm.time}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="awayTeam">
                    {gamesTxt['input.awayTeam']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Select
                    value={selectedAwayTeam}
                    options={awayTeamOptions}
                    onChange={onAwayTeamChange}
                    isDisabled={values.isPublished || values.isCancelled}
                  />
                  {errorsForm.awayTeamId && touched.awayTeamId && (
                    <div className="invalid-feedback-msg">
                      {errorsForm.awayTeamId}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="homeTeam">
                    {gamesTxt['input.homeTeam']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Select
                    value={selectedHomeTeam}
                    options={homeTeamOptions}
                    onChange={onHomeTeamChange}
                    isDisabled={values.isPublished || values.isCancelled}
                  />
                  {errorsForm.homeTeamId && touched.homeTeamId && (
                    <div className="invalid-feedback-msg">
                      {errorsForm.homeTeamId}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h3 className="my-4">{gamesTxt.bettingOptions}</h3>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="awayMoneyLine">
                    {gamesTxt['input.awayMoneyLine']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.awayMoneyLine"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.awayMoneyLine &&
                    touched.bet &&
                    touched.bet.awayMoneyLine && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.awayMoneyLine}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="homeMoneyLine">
                    {gamesTxt['input.homeMoneyLine']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.homeMoneyLine"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.homeMoneyLine &&
                    touched.bet &&
                    touched.bet.homeMoneyLine && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.homeMoneyLine}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="awayPointSpread">
                    {gamesTxt['input.awayPointSpread']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.awayPointSpread"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.awayPointSpread &&
                    touched.bet &&
                    touched.bet.awayPointSpread && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.awayPointSpread}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="homePointSpread">
                    {gamesTxt['input.homePointSpread']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.homePointSpread"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.homePointSpread &&
                    touched.bet &&
                    touched.bet.homePointSpread && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.homePointSpread}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="awayPointSpreadPayout">
                    {gamesTxt['input.awayPointSpreadPayout']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.awayPointSpreadPayout"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.awayPointSpreadPayout &&
                    touched.bet &&
                    touched.bet.awayPointSpreadPayout && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.awayPointSpreadPayout}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="homePointSpreadPayout">
                    {gamesTxt['input.homePointSpreadPayout']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.homePointSpreadPayout"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.homePointSpreadPayout &&
                    touched.bet &&
                    touched.bet.homePointSpreadPayout && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.homePointSpreadPayout}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="overPayout">
                    {gamesTxt['input.overPayout']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.overPayout"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.overPayout &&
                    touched.bet &&
                    touched.bet.overPayout && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.overPayout}
                      </div>
                    )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="underPayout">
                    {gamesTxt['input.underPayout']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.underPayout"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.underPayout &&
                    touched.bet &&
                    touched.bet.underPayout && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.underPayout}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="overUnder">
                    {gamesTxt['input.overUnder']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="bet.overUnder"
                    type="number"
                    className="form-control"
                    disabled={values.isCancelled || isScoreOut}
                  />
                  {errorsForm.bet &&
                    errorsForm.bet.overUnder &&
                    touched.bet &&
                    touched.bet.overUnder && (
                      <div className="invalid-feedback-msg">
                        {errorsForm.bet.overUnder}
                      </div>
                    )}
                </div>
              </div>
            </div>
            {values && values._id ? (
              <>
                <h3 className="my-4">{gamesTxt.score}</h3>
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="awayTeamScore">
                        {gamesTxt['input.awayTeamScore']}
                      </label>
                      <Field
                        name="awayTeamScore"
                        type="number"
                        className="form-control"
                        disabled={values.isCancelled || isScoreOut}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="homeTeamScore">
                        {gamesTxt['input.homeTeamScore']}
                      </label>
                      <Field
                        name="homeTeamScore"
                        type="number"
                        className="form-control"
                        disabled={values.isCancelled || isScoreOut}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {!values.isCancelled && !isScoreOut ? (
              <div className="row">
                <div className="col-12 text-right">
                  {values &&
                  values._id &&
                  !values.isPublished &&
                  user &&
                  user.role === 'superAdmin' ? (
                    <button
                      type="button"
                      onClick={() => onPublish(values._id)}
                      className="btn btn-dark mr-2"
                      disabled={loading}
                    >
                      {gamesTxt.btnPublish}
                    </button>
                  ) : null}
                  {values &&
                  values._id &&
                  user &&
                  user.role === 'superAdmin' ? (
                    <button
                      type="button"
                      onClick={() => onCancel(values._id)}
                      className="btn btn-danger mr-2"
                      disabled={loading}
                    >
                      Cancel Game
                    </button>
                  ) : null}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {gamesTxt.btnSave}
                  </button>
                </div>
              </div>
            ) : isScoreOut ? (
              <p
                style={{ fontSize: '2em', color: 'green', textAlign: 'center' }}
              >
                Game score is declared.
              </p>
            ) : (
              <p style={{ fontSize: '2em', color: 'red', textAlign: 'center' }}>
                Game is cancelled.
              </p>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default GameForm;
