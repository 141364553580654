/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import ApiService from '../../services/api';
import {
  addPool,
  addPoolError,
  addPoolSuccess,
  deletePool,
  deletePoolError,
  deletePoolSuccess,
  loadPool,
  loadPoolError,
  loadPools,
  loadPoolsError,
  loadPoolsSuccess,
  loadPoolSuccess,
} from './pools.slice';

export function* addPoolAsync(action: PayloadAction<any>) {
  const { data, errors } = yield call(ApiService.addPool, action.payload);

  if (errors) {
    yield put(addPoolError(errors));
  } else {
    yield put(addPoolSuccess(!!data));
  }
}

export function* loadPoolAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadPoolFormDetails, action.payload);

  if (!data) {
    yield put(loadPoolError(['404']));
  } else {
    yield put(loadPoolSuccess(data));
  }
}

export function* loadPoolsAsync() {
  const { data } = yield call(ApiService.getPoolsForTable);

  if (!data) {
    yield put(loadPoolsError(['500']));
  } else {
    yield put(loadPoolsSuccess(data));
  }
}

export function* deletePoolAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.deletePool, action.payload);

  if (!data) {
    yield put(deletePoolError(['404']));
  } else {
    yield put(deletePoolSuccess(data));
  }
}

export function* watchPoolsAsync() {
  yield takeEvery(addPool.toString(), addPoolAsync);
  yield takeEvery(deletePool.toString(), deletePoolAsync);
  yield takeEvery(loadPool.toString(), loadPoolAsync);
  yield takeEvery(loadPools.toString(), loadPoolsAsync);
}
