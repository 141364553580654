/* eslint-disable no-nested-ternary */
import React from 'react';

const Footer: React.FC = (): JSX.Element => {
  return (
    <footer className="footer-section">
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>Copyright &copy; 2021, All Right Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
