import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      access_token
    }
  }
`;

export const WHOAMI = gql`
  query whoAmI {
    whoAmI {
      _id
      email
      firstName
      lastName
      profilePicUrl
      mobile
      role
    }
  }
`;

export const ADD_TEAM = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      _id
      sportTypeId
      name
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      _id
      sportTypeId
      name
    }
  }
`;

export const GET_TEAM_FORM_DETAILS = gql`
  query teamDetail($id: String!) {
    teamDetail(_id: $id) {
      _id
      name
      sportTypeId
    }
  }
`;

export const GET_TEAMS_FOR_TABLE = gql`
  query listAllTeams($sportTypeId: String!) {
    listAllTeams(sportTypeId: $sportTypeId) {
      _id
      sportTypeId
      sporttype {
        name
      }
      name
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: String!) {
    deleteTeam(_id: $id)
  }
`;

export const GET_SPORT_TYPES = gql`
  query listAllSportTypes {
    listAllSportTypes {
      _id
      name
      icon
      comingSoon
    }
  }
`;

export const ADD_SPORT = gql`
  mutation createSportType($input: CreateSportTypeInput!) {
    createSportType(input: $input) {
      _id
      name
      icon
      comingSoon
    }
  }
`;

export const UPDATE_SPORT = gql`
  mutation updateSportType($input: UpdateSportTypeInput!) {
    updateSportType(input: $input) {
      _id
      name
      icon
      comingSoon
    }
  }
`;

export const GET_SPORT_FORM_DETAILS = gql`
  query sportTypeDetail($id: String!) {
    sportTypeDetail(_id: $id) {
      _id
      name
      icon
      comingSoon
    }
  }
`;

export const GET_SPORTS_FOR_TABLE = gql`
  query listAllSportTypes {
    listAllSportTypes {
      _id
      name
      icon
      comingSoon
    }
  }
`;

export const DELETE_SPORT = gql`
  mutation deleteSportType($id: String!) {
    deleteSportType(_id: $id)
  }
`;

export const ADD_POOL = gql`
  mutation createPool($input: CreatePoolInput!) {
    createPool(input: $input) {
      _id
    }
  }
`;

export const UPDATE_POOL = gql`
  mutation updatePool($input: UpdatePoolInput!) {
    updatePool(input: $input) {
      _id
    }
  }
`;

export const GET_POOL_FORM_DETAILS = gql`
  query poolDeatil($id: String!) {
    poolDeatil(_id: $id) {
      _id
      name
      amount
      entry
      value
      maxPlayer
      coins
      startTime
      hours
      commission
      noOfWinners
      winnerPercent
    }
  }
`;

export const GET_POOLS_FOR_TABLE = gql`
  query listAllPools {
    listAllPools {
      _id
      name
      amount
      entry
      value
      maxPlayer
      coins
      startTime
      hours
    }
  }
`;

export const DELETE_POOL = gql`
  mutation deletePool($id: String!) {
    deletePool(_id: $id)
  }
`;

export const ADD_GAME = gql`
  mutation createGame($input: CreateGameInput!) {
    createGame(input: $input) {
      _id
    }
  }
`;

export const UPDATE_GAME = gql`
  mutation updateGame($input: UpdateGameInput!) {
    updateGame(input: $input) {
      _id
    }
  }
`;

export const PUBLISH_GAME = gql`
  mutation publishGame($id: String!) {
    publishGame(_id: $id) {
      _id
    }
  }
`;

export const CANCEL_GAME = gql`
  mutation cancelGame($id: String!) {
    cancelGame(_id: $id) {
      _id
    }
  }
`;

export const DELETE_GAME = gql`
  mutation deleteGame($id: String!) {
    deleteGame(_id: $id)
  }
`;

export const GET_GAME_FORM_DETAILS = gql`
  query gameDetail($id: String!) {
    gameDetail(_id: $id) {
      _id
      awayTeamId
      homeTeamId
      date
      status
      isPublished
      isCancelled
      sportTypeId
      homeTeamScore
      awayTeamScore
      bet {
        _id
        homeMoneyLine
        awayMoneyLine
        homePointSpread
        awayPointSpread
        homePointSpreadPayout
        awayPointSpreadPayout
        overUnder
        overPayout
        underPayout
      }
    }
  }
`;

export const GAME_BETS = gql`
  query gameBets($id: String!) {
    gameBets(_id: $id) {
      _id
      points
      winPoints
      totalPoints
      isWin
      isRefundable
      user {
        _id
        firstName
        lastName
      }
      poolInfo {
        _id
        name
        value
      }
      userPoolOngoing {
        _id
        winnerRank
        winningAmount
        coins
        expiresAt
      }
      payload {
        betId
        bet {
          _id
          homeMoneyLine
          awayMoneyLine
          homePointSpread
          awayPointSpread
          homePointSpreadPayout
          awayPointSpreadPayout
          overUnder
          overPayout
          underPayout
        }
        gameId
        game {
          date
          awayTeam {
            name
          }
          homeTeam {
            name
          }
          sporttype {
            name
          }
          isCancelled
        }
        betTypeId
        betType {
          _id
          name
          value
        }
        teamType
        odds
        isWin
        isRefundable
      }
    }
  }
`;

export const GET_GAMES_FOR_TABLE = gql`
  query listAllGame {
    listAllGame {
      _id
      awayTeamId
      homeTeamId
      isPublished
      date
      status
      sporttype {
        name
      }
      awayTeam {
        name
      }
      homeTeam {
        name
      }
      bet {
        homeMoneyLine
        awayMoneyLine
        homePointSpread
        awayPointSpread
        homePointSpreadPayout
        awayPointSpreadPayout
        overUnder
        overPayout
        underPayout
      }
    }
  }
`;
