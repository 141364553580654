/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { RootState } from '../common/types';
import LayoutAdmin from './layout/AdminLayout';

type PrivateRouteProps = {
  children: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  ...rest
}: PrivateRouteProps): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.login);

  function getLayout() {
    if (!user) {
      return null;
    }

    return <LayoutAdmin>{children}</LayoutAdmin>;
  }

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        user ? (
          getLayout()
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
