import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { CreateTeamInput, SportTypeModel } from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';

type TeamProps = {
  onSubmit: (data: CreateTeamInput) => void;
  onDelete: (id: any) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreateTeamInput;
  sportTypes: SportTypeModel[];
};

const TeamForm: React.FC<TeamProps> = ({
  onSubmit,
  onDelete,
  loading,
  errors,
  initialValues,
  sportTypes,
}: TeamProps): JSX.Element => {
  const { teams: teamsTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.name) {
      errors.name = teamsTxt.nameErrorText;
    }

    if (!selectedSportType) {
      errors.sportTypeId = teamsTxt.sportTypeErrorText;
    }

    return errors;
  };

  const sportTypeOptions = sportTypes.map((sportType) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _id,
      name,
    } = sportType;
    return {
      value: _id,
      label: `${name}`,
      sportTypeObj: sportType,
    };
  });

  const [selectedSportType, setSelectedSportType] = useState(
    sportTypeOptions.find(({ value }) => value === initialValues.sportTypeId),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onSportTypeChange(val: any) {
    setSelectedSportType(val);
  }

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        input.sportTypeId = selectedSportType?.sportTypeObj?._id || '';

        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ errors, values, touched }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="sportType">
                    {teamsTxt['input.sportType']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Select
                    value={selectedSportType}
                    options={sportTypeOptions}
                    onChange={onSportTypeChange}
                  />
                  {errors.sportTypeId && touched.sportTypeId && (
                    <div className="invalid-feedback-msg">
                      {errors.sportTypeId}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="name">
                    {teamsTxt['input.name']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="name" type="text" className="form-control" />
                  {errors.name && touched.name && (
                    <div className="invalid-feedback-msg">{errors.name}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {teamsTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TeamForm;
