/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { LoginInput } from '../../common/types';

type ILoginProps = {
  onSubmit: (data: LoginInput) => void;
  errors: string[];
  loading: boolean;
};

const LoginForm: React.FC<ILoginProps> = ({
  onSubmit,
  errors,
  loading,
}: ILoginProps): JSX.Element => {
  const { login: loginTxt } = LanguageTexts;
  const history = useHistory();
  const initialValues: LoginInput = {
    type: 'admin',
    email: '',
    password: '',
  };

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(errorKeys, errors, LanguageTexts.login);

  const handleBlur = (e: {
    target: {
      value: string;
      classList: {
        add: (arg0: string) => void;
        remove: (arg0: string) => void;
      };
    };
  }) => {
    if (e.target.value !== '') {
      e.target.classList.add('has-val');
    } else {
      e.target.classList.remove('has-val');
    }
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = loginTxt.emailErrorText;
    }

    if (!values.password) {
      errors.password = loginTxt.passwordErrorText;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isValidating }) => (
          <Form className="my-login-validation">
            <div className="form-group">
              <label htmlFor="email">{loginTxt.emailAddressLabel}</label>
              <Field name="email" type="text" className="form-control" />
              {errors.email && touched.email && (
                <div className="invalid-feedback-msg">{errors.email}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="email">{loginTxt.passwordLabel}</label>
              <Field name="password" type="password" className="form-control" />
              {errors.password && touched.password && (
                <div className="invalid-feedback-msg">{errors.password}</div>
              )}
            </div>

            {formattedErrors.map((error) => (
              <p
                className="invalid-feedback-msg"
                style={{ fontWeight: 'bold' }}
                key={error}
              >
                {error}
              </p>
            ))}

            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loginTxt.loginBtnText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

LoginForm.defaultProps = {};

export default LoginForm;
