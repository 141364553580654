/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreatePoolInput, PoolModel, PoolsState } from '../../common/types';

const initialState: PoolsState = {
  loading: false,
  success: null,
  errors: [],
  pools: null,
  addPool: {
    loading: false,
    success: null,
    errors: [],
  },
  loadPool: {
    loading: false,
    success: null,
    errors: [],
  },
  deletePool: {
    loading: false,
    success: null,
    errors: [],
  },
};

const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadPools(state, action: PayloadAction<undefined>) {
      state.loading = true;
      state.errors = [];
    },
    loadPoolsSuccess(state, action: PayloadAction<PoolModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.pools = action.payload;
    },
    loadPoolsError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadPools(state) {
      state.loadPool.success = null;
      state.loadPool.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addPool(state, action: PayloadAction<CreatePoolInput>) {
      state.addPool.loading = true;
      state.addPool.errors = [];
    },
    addPoolSuccess(state, action: PayloadAction<boolean>) {
      state.addPool.loading = false;
      state.addPool.success = action.payload;
    },
    addPoolError(state, action: PayloadAction<string[]>) {
      state.addPool.loading = false;
      state.addPool.errors = action.payload;
    },
    resetAddPoolSuccess(state) {
      state.addPool.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadPool(state, action: PayloadAction<string>) {
      state.loadPool.loading = true;
      state.loadPool.errors = [];
    },
    loadPoolSuccess(state, action: PayloadAction<PoolModel>) {
      state.loadPool.loading = false;
      state.loadPool.success = action.payload;
    },
    loadPoolError(state, action: PayloadAction<string[]>) {
      state.loadPool.loading = false;
      state.loadPool.errors = action.payload;
    },
    resetLoadPool(state) {
      state.loadPool.success = null;
      state.loadPool.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deletePool(state, action: PayloadAction<string>) {
      state.deletePool.loading = true;
      state.deletePool.errors = [];
    },
    deletePoolSuccess(state, action: PayloadAction<boolean>) {
      state.deletePool.loading = false;
      state.deletePool.success = action.payload;
    },
    deletePoolError(state, action: PayloadAction<string[]>) {
      state.deletePool.loading = false;
      state.deletePool.errors = action.payload;
    },
    resetDeletePoolSuccess(state) {
      state.deletePool.success = null;
    },
  },
});

export const {
  addPool,
  addPoolSuccess,
  addPoolError,
  resetAddPoolSuccess,
  loadPool,
  loadPoolSuccess,
  loadPoolError,
  resetLoadPool,
  loadPools,
  loadPoolsSuccess,
  loadPoolsError,
  resetLoadPools,
  deletePool,
  deletePoolError,
  deletePoolSuccess,
  resetDeletePoolSuccess,
} = poolsSlice.actions;

export const { name: poolsSliceName, reducer: poolsSliceReducer } = poolsSlice;
