/* eslint-disable jsx-a11y/label-has-associated-control */
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreateSportInput, RootState, SportModel } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import SportForm from './SportForm';
import { loadSport, resetLoadSport } from './sports.slice';

type SportRecordPageParams = {
  onSubmit: (data: CreateSportInput) => void;
  onDelete: (id: string) => void;
  errors: string[];
  loading: boolean;
};

const SportRecordPage = ({
  onSubmit: onSubmitParent,
  onDelete: onDeleteParent,
  errors,
  loading,
}: SportRecordPageParams): JSX.Element => {
  const { sportId } = useParams<{ sportId: string }>();
  const { sports: sportsTxt, app: appTxt } = LanguageTexts;

  const [sportLoaded, setSportLoaded] = useState<boolean>();
  const [sports, setSports] = useState<SportModel>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadSport: {
      loading: loadSportLoading,
      errors: loadSportErrors,
      success: loadSportSuccess,
    },
  } = useSelector((state: RootState) => state.sports);

  useEffect(() => {
    if (sportId && !sportLoaded) {
      dispatch(loadSport(sportId));
      setSportLoaded(true);
    }
  }, [sportLoaded, sportId, dispatch]);

  useEffect(() => {
    if (loadSportErrors.length > 0) {
      dispatch(resetLoadSport());
      history.push(AppRoutePath.Sports);
    } else if (loadSportSuccess) {
      setSports(loadSportSuccess);
      dispatch(resetLoadSport());
    }
  }, [loadSportErrors, loadSportSuccess, dispatch, history]);

  function onSubmit({ ...restInput }: CreateSportInput) {
    onSubmitParent({
      ...restInput,
    });
  }

  function onDelete(id: any) {
    onDeleteParent(id);
  }

  if (sportId && (!sportLoaded || loadSportLoading)) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let sportInfo: any;

  if (sports) {
    sportInfo = cloneDeep(sports);
  }

  const sportInfoInit: CreateSportInput = sportInfo || {
    name: '',
    icon: '',
    comingSoon: false,
  };

  return (
    <section>
      <h1 className="primary-heading mb-4">
        {sportId ? sportsTxt.updateSport : sportsTxt.newSport}
      </h1>

      <p className="invalid-feedback-msg text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <SportForm
          onSubmit={onSubmit}
          onDelete={onDelete}
          initialValues={sportInfoInit}
          errors={errors}
          loading={loading}
        />
      </div>
    </section>
  );
};

export default SportRecordPage;
