export enum PublicRoutePath {
  Login = '/login',
}

export enum AppRoutePath {
  Dashboard = '/app/dashboard',
  Sports = '/app/sports',
  SportsCreate = '/app/sports/create',
  SportsUpdate = '/app/sports/update/:sportId',
  SportsDelete = '/app/sports/delete/:sportId',
  Teams = '/app/teams',
  TeamsCreate = '/app/teams/create',
  TeamsUpdate = '/app/teams/update/:teamId',
  TeamsDelete = '/app/teams/delete/:teamId',
  Pools = '/app/pools',
  PoolsCreate = '/app/pools/create',
  PoolsUpdate = '/app/pools/update/:poolId',
  PoolsDelete = '/app/pools/delete/:poolId',
  Games = '/app/games',
  GamesCreate = '/app/games/create',
  GamesUpdate = '/app/games/update/:gameId',
  GamesDelete = '/app/games/delete/:gameId',
  GamesBets = '/app/games/bets/:gameId',
}

export const TOKEN_KEY = 'accessToken';

export enum BetTypes {
  PS = 'Point Spread',
  ML = 'Money Line',
  TP = 'Total Points',
  PARLAY = 'Parlay',
}

export enum TeamTypes {
  HOME = 'home',
  AWAY = 'away',
}
