import 'react-confirm-alert/src/react-confirm-alert.css';

import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreatePoolInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import PoolListPage from './PoolListPage';
import PoolRecordPage from './PoolRecordPage';
import {
  addPool,
  deletePool,
  resetAddPoolSuccess,
  resetDeletePoolSuccess,
} from './pools.slice';

const PoolsPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const { pools: poolsTxt, app: appTxt } = LanguageTexts;

  const {
    addPool: {
      loading: addPoolLoading,
      success: addPoolSuccess,
      errors: addPoolErrors,
    },
    deletePool: {
      loading: deletePoolLoading,
      success: deletePoolSuccess,
      errors: deletePoolErrors,
    },
  } = useSelector((state: RootState) => state.pools);

  function onSubmit({ ...data }: CreatePoolInput) {
    dispatch(
      addPool({
        ...data,
      }),
    );
  }

  function onDelete(id: any) {
    confirmAlert({
      title: poolsTxt.deletePool,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => dispatch(deletePool(id)),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  useEffect(() => {
    if (addPoolSuccess) {
      dispatch(resetAddPoolSuccess());
      toast.success(poolsTxt.createSuccessMsg);
      history.push(AppRoutePath.Pools);
    }
    if (deletePoolSuccess) {
      dispatch(resetDeletePoolSuccess());
      toast.success(poolsTxt.deleteSuccessMsg);
      history.push(AppRoutePath.Pools);
    }
  }, [addPoolSuccess, deletePoolSuccess, dispatch, history, poolsTxt]);

  return (
    <div>
      <Switch>
        <PrivateRoute path={AppRoutePath.PoolsUpdate}>
          {!addPoolSuccess ? (
            <PoolRecordPage
              key="updatepoolform"
              errors={addPoolErrors}
              loading={addPoolLoading}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.PoolsCreate}>
          {!addPoolSuccess ? (
            <PoolRecordPage
              key="createpoolform"
              errors={addPoolErrors}
              loading={addPoolLoading}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path}>
          <PoolListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default PoolsPage;
