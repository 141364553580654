/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LangaugeTexts from '../../common/language';
import { RootState } from '../../common/types';
import { logout } from '../../features/login/login.slice';

const TopHeader: React.FC = (): JSX.Element => {
  const { topHeader: topHeaderTxt } = LangaugeTexts;
  const { app: appTxt } = LangaugeTexts;
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.login);

  function doLogout() {
    dispatch(logout());
  }

  const windowScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div id="topbar" className="d-flex align-items-center bg-white">
      <div className="container-fluid d-flex align-items-center">
        <div className="contact-info mr-auto">
          <Link to="/" onClick={windowScrollTop}>
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}website/logo.png`}
              alt=""
              width="200px"
              height="auto"
            />
          </Link>
        </div>
        {user ? (
          <div>
            <div>
              <ul className="c-header-nav ml-auto mr-0">
                <li className="c-header-nav-item dropdown ml-2 mr-3">
                  <a
                    className="c-header-nav-link"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="c-avatar">
                      <img
                        className="c-avatar-img"
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/user.png`}
                        width="50px"
                        height="50px"
                        alt="user"
                      />
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right pt-0">
                    <Link className="dropdown-item" to="/" onClick={doLogout}>
                      {topHeaderTxt.dropdownLogoutMenu}
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TopHeader;
