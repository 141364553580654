import 'react-confirm-alert/src/react-confirm-alert.css';

import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreateGameInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import GameBetsPage from './GameBetsPage';
import GameListPage from './GameListPage';
import GameRecordPage from './GameRecordPage';
import {
  addGame,
  cancelGame,
  deleteGame,
  publishGame,
  resetAddGameSuccess,
  resetCancelGameSuccess,
  resetDeleteGameSuccess,
  resetPublishGameSuccess,
} from './games.slice';

const GamesPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const { games: gamesTxt, app: appTxt } = LanguageTexts;

  const {
    addGame: {
      loading: addGameLoading,
      success: addGameSuccess,
      errors: addGameErrors,
    },
    publishGame: {
      loading: publishGameLoading,
      success: publishGameSuccess,
      errors: publishGameErrors,
    },
    cancelGame: {
      loading: cancelGameLoading,
      success: cancelGameSuccess,
      errors: cancelGameErrors,
    },
    deleteGame: {
      loading: deleteGameLoading,
      success: deleteGameSuccess,
      errors: deleteGameErrors,
    },
  } = useSelector((state: RootState) => state.games);

  function onSubmit({ ...data }: CreateGameInput) {
    dispatch(
      addGame({
        ...data,
      }),
    );
  }

  function onPublish(id: any) {
    dispatch(publishGame(id));
  }

  function onCancel(id: any) {
    confirmAlert({
      title: 'Cancel Game',
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => dispatch(cancelGame(id)),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  function onDelete(id: any) {
    confirmAlert({
      title: gamesTxt.deleteGame,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => dispatch(deleteGame(id)),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  useEffect(() => {
    if (addGameSuccess) {
      dispatch(resetAddGameSuccess());
      toast.success(gamesTxt.createSuccessMsg);
      history.push(AppRoutePath.Games);
    }
    if (publishGameSuccess) {
      dispatch(resetPublishGameSuccess());
      toast.success(gamesTxt.publishSuccessMsg);
      history.push(AppRoutePath.Games);
    }
    if (cancelGameSuccess) {
      dispatch(resetCancelGameSuccess());
      toast.success('Game cancelled successfully.');
      history.push(AppRoutePath.Games);
    }
    if (deleteGameSuccess) {
      dispatch(resetDeleteGameSuccess());
      toast.success(gamesTxt.deleteSuccessMsg);
      history.push(AppRoutePath.Games);
    }
  }, [
    addGameSuccess,
    publishGameSuccess,
    cancelGameSuccess,
    deleteGameSuccess,
    dispatch,
    history,
    gamesTxt,
  ]);

  return (
    <div>
      <Switch>
        <PrivateRoute path={AppRoutePath.GamesBets}>
          <GameBetsPage key="gameBetsPage" />
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.GamesUpdate}>
          {!addGameSuccess ? (
            <GameRecordPage
              key="updateGameform"
              errors={addGameErrors}
              loading={addGameLoading}
              onSubmit={onSubmit}
              onPublish={onPublish}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.GamesCreate}>
          {!addGameSuccess ? (
            <GameRecordPage
              key="createGameform"
              errors={addGameErrors}
              loading={addGameLoading}
              onSubmit={onSubmit}
              onPublish={onPublish}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path}>
          <GameListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default GamesPage;
