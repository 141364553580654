import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { TOKEN_KEY } from '../common/constants';
import { RootState } from '../common/types';
import { whoami, whoamiSuccess } from '../features/login/login.slice';
import RootRoutes from './rootRoutes';

function App(): JSX.Element {
  const accessToken = localStorage.getItem(TOKEN_KEY);
  const dispatch = useDispatch();

  const {
    user,
    whoamiState: { loading: whoAmILoading },
  } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    if (!accessToken) {
      dispatch(whoamiSuccess(null));
    } else if (accessToken && !user) {
      dispatch(whoami());
    }
  }, [accessToken, user, dispatch]);

  return (
    <>
      <Helmet>
        <title>ShvrkPools Admin</title>
      </Helmet>
      <RootRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
