import moment from 'moment';

import client from '../app/apolo.client';
import { TOKEN_KEY } from '../common/constants';
import {
  ADD_GAME,
  ADD_POOL,
  ADD_SPORT,
  ADD_TEAM,
  CANCEL_GAME,
  DELETE_GAME,
  DELETE_POOL,
  DELETE_SPORT,
  DELETE_TEAM,
  GAME_BETS,
  GET_GAME_FORM_DETAILS,
  GET_GAMES_FOR_TABLE,
  GET_POOL_FORM_DETAILS,
  GET_POOLS_FOR_TABLE,
  GET_SPORT_FORM_DETAILS,
  GET_SPORT_TYPES,
  GET_SPORTS_FOR_TABLE,
  GET_TEAM_FORM_DETAILS,
  GET_TEAMS_FOR_TABLE,
  LOGIN,
  PUBLISH_GAME,
  UPDATE_GAME,
  UPDATE_POOL,
  UPDATE_SPORT,
  UPDATE_TEAM,
  WHOAMI,
} from '../common/gql';
import {
  extractErrors,
  formatApiException,
  formatApiResponse,
} from '../common/helper';
import {
  CreateGameInput,
  CreatePoolInput,
  CreateSportInput,
  CreateTeamInput,
  GameModel,
  LoginInput,
  PoolModel,
  SportModel,
  SportTypesResponse,
  TeamModel,
  UserModel,
} from '../common/types';

export default class ApiService {
  static async login(
    payload: LoginInput,
  ): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: LOGIN,
        variables: { input: payload },
      });

      return formatApiResponse('login', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async whoami(): Promise<{
    data: UserModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({ query: WHOAMI });
      return formatApiResponse('whoAmI', data, errors ? [...errors] : null);
    } catch (e) {
      if (e.message === 'Unauthorized') {
        localStorage.removeItem(TOKEN_KEY);
        window.location.replace('/');
      }

      return formatApiException(e);
    }
  }

  static async addTeam(
    payload: CreateTeamInput,
  ): Promise<{
    data: TeamModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: !payload._id ? ADD_TEAM : UPDATE_TEAM,
        variables: { input: payload },
      });

      return formatApiResponse(
        !payload._id ? 'createTeam' : 'updateTeam',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadTeamFormDetails(
    id: string,
  ): Promise<{
    data: TeamModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_TEAM_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse('teamDetail', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getTeamsForTable(
    sportTypeId: string,
  ): Promise<{
    data: TeamModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_TEAMS_FOR_TABLE,
        variables: { sportTypeId },
      });

      return formatApiResponse(
        'listAllTeams',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getSportTypes(): Promise<{
    data: SportTypesResponse | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_SPORT_TYPES,
      });

      const sportTypes = data
        ? formatApiResponse('listAllSportTypes', data, null).data
        : null;

      return {
        data: {
          sportTypes,
        },
        errors: errors ? extractErrors([...errors]) : null,
      };
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addSport(
    payload: CreateSportInput,
  ): Promise<{
    data: SportModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: !payload._id ? ADD_SPORT : UPDATE_SPORT,
        variables: { input: payload },
      });

      return formatApiResponse(
        !payload._id ? 'createSportType' : 'updateSportType',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadSportFormDetails(
    id: string,
  ): Promise<{
    data: SportModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_SPORT_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse(
        'sportTypeDetail',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getSportsForTable(): Promise<{
    data: SportModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_SPORTS_FOR_TABLE,
        variables: {},
      });

      return formatApiResponse(
        'listAllSportTypes',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async deleteSport(
    id: string,
  ): Promise<{
    data: SportModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: DELETE_SPORT,
        variables: { id },
      });

      return formatApiResponse(
        'deleteSportType',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addPool(
    payload: CreatePoolInput,
  ): Promise<{
    data: PoolModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: !payload._id ? ADD_POOL : UPDATE_POOL,
        variables: { input: payload },
      });

      return formatApiResponse(
        !payload._id ? 'createPool' : 'updatePool',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadPoolFormDetails(
    id: string,
  ): Promise<{
    data: PoolModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_POOL_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse('poolDeatil', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getPoolsForTable(): Promise<{
    data: PoolModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_POOLS_FOR_TABLE,
        variables: {},
      });

      return formatApiResponse(
        'listAllPools',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async deletePool(
    id: string,
  ): Promise<{
    data: PoolModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: DELETE_POOL,
        variables: { id },
      });

      return formatApiResponse('deletePool', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async addGame(
    payload: any,
  ): Promise<{
    data: GameModel | null;
    errors: string[] | null;
  }> {
    try {
      payload.date = moment(payload.date);
      console.log(payload);
      const { data, errors } = await client.mutate({
        mutation: !payload._id ? ADD_GAME : UPDATE_GAME,
        variables: { input: payload },
      });

      return formatApiResponse(
        !payload._id ? 'createGame' : 'updateGame',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async publishGame(
    id: string,
  ): Promise<{
    data: GameModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: PUBLISH_GAME,
        variables: { id },
      });

      return formatApiResponse(
        'publishGame',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async cancelGame(
    id: string,
  ): Promise<{
    data: GameModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: CANCEL_GAME,
        variables: { id },
      });

      return formatApiResponse('cancelGame', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async deleteGame(
    id: string,
  ): Promise<{
    data: GameModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: DELETE_GAME,
        variables: { id },
      });

      return formatApiResponse('deleteGame', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async deleteTeam(
    id: string,
  ): Promise<{
    data: GameModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.mutate({
        mutation: DELETE_TEAM,
        variables: { id },
      });

      return formatApiResponse('deleteTeam', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadGameFormDetails(
    id: string,
  ): Promise<{
    data: GameModel | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_GAME_FORM_DETAILS,
        variables: { id },
      });

      return formatApiResponse('gameDetail', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async loadGameBets(
    id: string,
  ): Promise<{
    data: any[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GAME_BETS,
        variables: { id },
      });

      return formatApiResponse('gameBets', data, errors ? [...errors] : null);
    } catch (e) {
      return formatApiException(e);
    }
  }

  static async getGamesForTable(): Promise<{
    data: GameModel[] | null;
    errors: string[] | null;
  }> {
    try {
      const { data, errors } = await client.query({
        query: GET_GAMES_FOR_TABLE,
        variables: {},
      });

      return formatApiResponse(
        'listAllGame',
        data,
        errors ? [...errors] : null,
      );
    } catch (e) {
      return formatApiException(e);
    }
  }
}
