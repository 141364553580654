/* eslint-disable jsx-a11y/label-has-associated-control */
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreateTeamInput, RootState, TeamModel } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import TeamForm from './TeamForm';
import {
  loadSportTypes,
  loadTeam,
  resetLoadSportTypes,
  resetLoadTeam,
} from './teams.slice';

type TeamRecordPageParams = {
  onSubmit: (data: CreateTeamInput) => void;
  onDelete: (id: string) => void;
  errors: string[];
  loading: boolean;
};

const TeamRecordPage = ({
  onSubmit: onSubmitParent,
  onDelete: onDeleteParent,
  errors,
  loading,
}: TeamRecordPageParams): JSX.Element => {
  const { teamId } = useParams<{ teamId: string }>();
  const { teams: teamsTxt, app: appTxt } = LanguageTexts;

  const [teamLoaded, setTeamLoaded] = useState<boolean>();
  const [teams, setTeams] = useState<TeamModel>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadTeam: {
      loading: loadTeamLoading,
      errors: loadTeamErrors,
      success: loadTeamSuccess,
    },
    loadSportTypes: {
      loading: loadSportTypesLoading,
      errors: loadSportTypesErrors,
      success: loadSportTypesSuccess,
    },
    sportTypes,
  } = useSelector((state: RootState) => state.teams);

  const [sportTypesLoadRequested, setSportTypesRequested] = useState<boolean>();

  useEffect(() => {
    if (teamId && !teamLoaded) {
      dispatch(loadTeam(teamId));
      setTeamLoaded(true);
    }
  }, [teamLoaded, teamId, dispatch]);

  useEffect(() => {
    if (loadTeamErrors.length > 0) {
      dispatch(resetLoadTeam());
      history.push(AppRoutePath.Teams);
    } else if (loadTeamSuccess) {
      setTeams(loadTeamSuccess);
      dispatch(resetLoadTeam());
    }
  }, [loadTeamErrors, loadTeamSuccess, dispatch, history]);

  // load sport types
  useEffect(() => {
    if (!sportTypesLoadRequested) {
      dispatch(loadSportTypes());
      setSportTypesRequested(true);
    }
  }, [sportTypesLoadRequested, dispatch]);

  useEffect(() => {
    if (loadSportTypesErrors.length > 0) {
      dispatch(resetLoadSportTypes());
      history.push(AppRoutePath.Teams);
    } else if (loadSportTypesSuccess) {
      dispatch(resetLoadSportTypes());
    }
  }, [loadSportTypesErrors, loadSportTypesSuccess, dispatch, history]);

  function onSubmit({ ...restInput }: CreateTeamInput) {
    onSubmitParent({
      ...restInput,
    });
  }

  function onDelete(id: any) {
    onDeleteParent(id);
  }

  if (
    (teamId && (!teamLoaded || loadTeamLoading)) ||
    !sportTypesLoadRequested ||
    loadSportTypesLoading
  ) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let teamInfo: any;

  if (teams) {
    teamInfo = cloneDeep(teams);
  }

  const teamInfoInit: CreateTeamInput = teamInfo || {
    name: '',
    sportTypeId: '',
  };

  return (
    <section id="team-form">
      <h1 className="primary-heading mb-4">
        {teamId ? teamsTxt.updateTeam : teamsTxt.newTeam}
      </h1>

      <p className="invalid-feedback-msg text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <TeamForm
          onSubmit={onSubmit}
          onDelete={onDelete}
          sportTypes={sportTypes || []}
          initialValues={teamInfoInit}
          errors={errors}
          loading={loading}
        />
      </div>
    </section>
  );
};

export default TeamRecordPage;
