/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateGameInput,
  GameModel,
  GamesState,
  SportTypesResponse,
  TeamModel,
} from '../../common/types';

const initialState: GamesState = {
  loading: false,
  success: null,
  errors: [],
  games: null,
  gameBets: null,
  addGame: {
    loading: false,
    success: null,
    errors: [],
  },
  publishGame: {
    loading: false,
    success: null,
    errors: [],
  },
  cancelGame: {
    loading: false,
    success: null,
    errors: [],
  },
  deleteGame: {
    loading: false,
    success: null,
    errors: [],
  },
  loadGame: {
    loading: false,
    success: null,
    errors: [],
  },
  loadGameBets: {
    loading: false,
    success: null,
    errors: [],
  },
  sportTypes: null,
  loadSportTypes: {
    loading: false,
    success: null,
    errors: [],
  },
  teams: null,
  loadTeams: {
    loading: false,
    success: null,
    errors: [],
  },
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadGames(state, action: PayloadAction<undefined>) {
      state.loading = true;
      state.errors = [];
    },
    loadGamesSuccess(state, action: PayloadAction<GameModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.games = action.payload;
    },
    loadGamesError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadGames(state) {
      state.loadGame.success = null;
      state.loadGame.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadGameBets(state, action: PayloadAction<string>) {
      state.gameBets = null;
      state.loadGameBets.loading = true;
      state.loadGameBets.errors = [];
    },
    loadGameBetsSuccess(state, action: PayloadAction<any[]>) {
      state.loadGameBets.loading = false;
      state.loadGameBets.success = !!action.payload;
      state.gameBets = action.payload;
    },
    loadGameBetsError(state, action: PayloadAction<string[]>) {
      state.loadGameBets.loading = false;
      state.loadGameBets.errors = action.payload;
    },
    resetLoadGameBets(state) {
      state.loadGameBets.success = null;
      state.loadGameBets.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addGame(state, action: PayloadAction<CreateGameInput>) {
      state.addGame.loading = true;
      state.addGame.errors = [];
    },
    addGameSuccess(state, action: PayloadAction<boolean>) {
      state.addGame.loading = false;
      state.addGame.success = action.payload;
    },
    addGameError(state, action: PayloadAction<string[]>) {
      state.addGame.loading = false;
      state.addGame.errors = action.payload;
    },
    resetAddGameSuccess(state) {
      state.addGame.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    publishGame(state, action: PayloadAction<string>) {
      state.publishGame.loading = true;
      state.publishGame.errors = [];
    },
    publishGameSuccess(state, action: PayloadAction<boolean>) {
      state.publishGame.loading = false;
      state.publishGame.success = action.payload;
    },
    publishGameError(state, action: PayloadAction<string[]>) {
      state.publishGame.loading = false;
      state.publishGame.errors = action.payload;
    },
    resetPublishGameSuccess(state) {
      state.publishGame.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cancelGame(state, action: PayloadAction<string>) {
      state.cancelGame.loading = true;
      state.cancelGame.errors = [];
    },
    cancelGameSuccess(state, action: PayloadAction<boolean>) {
      state.cancelGame.loading = false;
      state.cancelGame.success = action.payload;
    },
    cancelGameError(state, action: PayloadAction<string[]>) {
      state.cancelGame.loading = false;
      state.cancelGame.errors = action.payload;
    },
    resetCancelGameSuccess(state) {
      state.cancelGame.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteGame(state, action: PayloadAction<string>) {
      state.deleteGame.loading = true;
      state.deleteGame.errors = [];
    },
    deleteGameSuccess(state, action: PayloadAction<boolean>) {
      state.deleteGame.loading = false;
      state.deleteGame.success = action.payload;
    },
    deleteGameError(state, action: PayloadAction<string[]>) {
      state.deleteGame.loading = false;
      state.deleteGame.errors = action.payload;
    },
    resetDeleteGameSuccess(state) {
      state.deleteGame.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadGame(state, action: PayloadAction<string>) {
      state.loadGame.loading = true;
      state.loadGame.errors = [];
    },
    loadGameSuccess(state, action: PayloadAction<GameModel>) {
      state.loadGame.loading = false;
      state.loadGame.success = action.payload;
    },
    loadGameError(state, action: PayloadAction<string[]>) {
      state.loadGame.loading = false;
      state.loadGame.errors = action.payload;
    },
    resetLoadGame(state) {
      state.loadGame.success = null;
      state.loadGame.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadSportTypes(state, action: PayloadAction<undefined>) {
      state.loadSportTypes.loading = true;
      state.loadSportTypes.errors = [];
    },
    loadSportTypesSuccess(state, action: PayloadAction<SportTypesResponse>) {
      console.log(action.payload);
      state.loadSportTypes.loading = false;
      state.loadSportTypes.success = !!action.payload;
      state.sportTypes = action.payload.sportTypes;
    },
    loadSportTypesError(state, action: PayloadAction<string[]>) {
      state.loadSportTypes.loading = false;
      state.loadSportTypes.errors = action.payload;
    },
    resetLoadSportTypes(state) {
      state.loadSportTypes.success = null;
      state.loadSportTypes.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadTeams(state, action: PayloadAction<string>) {
      state.loadTeams.loading = true;
      state.loadTeams.errors = [];
    },
    loadTeamsSuccess(state, action: PayloadAction<TeamModel[]>) {
      console.log(action.payload);
      state.loadTeams.loading = false;
      state.loadTeams.success = !!action.payload;
      state.teams = action.payload;
    },
    loadTeamsError(state, action: PayloadAction<string[]>) {
      state.loadTeams.loading = false;
      state.loadTeams.errors = action.payload;
    },
    resetLoadTeams(state) {
      state.loadTeams.success = null;
      state.loadTeams.errors = [];
    },
  },
});

export const {
  addGame,
  addGameSuccess,
  addGameError,
  resetAddGameSuccess,
  publishGame,
  publishGameSuccess,
  publishGameError,
  resetPublishGameSuccess,
  cancelGame,
  cancelGameSuccess,
  cancelGameError,
  resetCancelGameSuccess,
  deleteGame,
  deleteGameSuccess,
  deleteGameError,
  resetDeleteGameSuccess,
  loadGame,
  loadGameSuccess,
  loadGameError,
  resetLoadGame,
  loadGameBets,
  loadGameBetsSuccess,
  loadGameBetsError,
  resetLoadGameBets,
  loadGames,
  loadGamesSuccess,
  loadGamesError,
  resetLoadGames,
  loadSportTypes,
  loadSportTypesSuccess,
  loadSportTypesError,
  resetLoadSportTypes,
  loadTeams,
  loadTeamsSuccess,
  loadTeamsError,
  resetLoadTeams,
} = gamesSlice.actions;

export const { name: gamesSliceName, reducer: gamesSliceReducer } = gamesSlice;
