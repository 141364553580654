import 'react-confirm-alert/src/react-confirm-alert.css';

import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreateTeamInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import TeamListPage from './TeamListPage';
import TeamRecordPage from './TeamRecordPage';
import {
  addTeam,
  deleteTeam,
  resetAddTeamSuccess,
  resetDeleteTeamSuccess,
} from './teams.slice';

const TeamsPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const { teams: teamsTxt, app: appTxt } = LanguageTexts;

  const {
    addTeam: {
      loading: addTeamLoading,
      success: addTeamSuccess,
      errors: addTeamErrors,
    },
    deleteTeam: {
      loading: deleteTeamLoading,
      success: deleteTeamSuccess,
      errors: deleteTeamErrors,
    },
  } = useSelector((state: RootState) => state.teams);

  function onSubmit({ ...data }: CreateTeamInput) {
    dispatch(
      addTeam({
        ...data,
      }),
    );
  }

  function onDelete(id: any) {
    confirmAlert({
      title: teamsTxt.deleteTeam,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => dispatch(deleteTeam(id)),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  useEffect(() => {
    if (addTeamSuccess) {
      dispatch(resetAddTeamSuccess());
      toast.success(teamsTxt.createSuccessMsg);
      history.push(AppRoutePath.Teams);
    }
    if (deleteTeamSuccess) {
      dispatch(resetDeleteTeamSuccess());
      toast.success(teamsTxt.deleteSuccessMsg);
      history.push(AppRoutePath.Teams);
    }
  }, [addTeamSuccess, deleteTeamSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute path={AppRoutePath.TeamsUpdate}>
          {!addTeamSuccess ? (
            <TeamRecordPage
              key="updateteamform"
              errors={addTeamErrors}
              loading={addTeamLoading}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.TeamsCreate}>
          {!addTeamSuccess ? (
            <TeamRecordPage
              key="createTeamform"
              errors={addTeamErrors}
              loading={addTeamLoading}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path}>
          <TeamListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default TeamsPage;
