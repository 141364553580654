import 'rc-time-picker/assets/index.css';

import moment from 'moment';
import TimePicker from 'rc-time-picker';
import React, { FC } from 'react';

type PoolTimePickerProps = {
  value: moment.Moment | undefined;
  onChange: (newValue: moment.Moment) => void;
  disabled?: boolean;
};

const PoolTimePicker: FC<PoolTimePickerProps> = ({
  value,
  disabled,
  onChange,
}: PoolTimePickerProps) => {
  return (
    <TimePicker
      value={value || undefined}
      defaultOpenValue={moment()}
      disabled={disabled}
      showSecond={false}
      use12Hours
      onChange={(newValue) => {
        onChange(newValue.set({ second: 0, millisecond: 0 }).clone());
      }}
      minuteStep={15}
      inputReadOnly
      className="form-control"
    />
  );
};

PoolTimePicker.defaultProps = {
  disabled: false,
};

export default PoolTimePicker;
