import 'react-confirm-alert/src/react-confirm-alert.css';

import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreateSportInput, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PrivateRoute from '../../components/PrivateRoute';
import SportListPage from './SportListPage';
import SportRecordPage from './SportRecordPage';
import {
  addSport,
  deleteSport,
  resetAddSportSuccess,
  resetDeleteSportSuccess,
} from './sports.slice';

const SportsPage = (): JSX.Element => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const { sports: sportsTxt, app: appTxt } = LanguageTexts;

  const {
    addSport: {
      loading: addSportLoading,
      success: addSportSuccess,
      errors: addSportErrors,
    },
    deleteSport: {
      loading: deleteSportLoading,
      success: deleteSportSuccess,
      errors: deleteSportErrors,
    },
  } = useSelector((state: RootState) => state.sports);

  function onSubmit({ ...data }: CreateSportInput) {
    dispatch(
      addSport({
        ...data,
      }),
    );
  }

  function onDelete(id: any) {
    confirmAlert({
      title: sportsTxt.deleteSport,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => dispatch(deleteSport(id)),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  useEffect(() => {
    if (addSportSuccess) {
      dispatch(resetAddSportSuccess());
      toast.success(sportsTxt.createSuccessMsg);
      history.push(AppRoutePath.Sports);
    }
    if (deleteSportSuccess) {
      dispatch(resetDeleteSportSuccess());
      toast.success(sportsTxt.deleteSuccessMsg);
      history.push(AppRoutePath.Sports);
    }
  }, [addSportSuccess, deleteSportSuccess, dispatch, history]);

  return (
    <div>
      <Switch>
        <PrivateRoute path={AppRoutePath.SportsUpdate}>
          {!addSportSuccess ? (
            <SportRecordPage
              key="updateSportform"
              errors={addSportErrors}
              loading={addSportLoading}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={AppRoutePath.SportsCreate}>
          {!addSportSuccess ? (
            <SportRecordPage
              key="createSportform"
              errors={addSportErrors}
              loading={addSportLoading}
              onSubmit={onSubmit}
              onDelete={onDelete}
            />
          ) : (
            <AppLoader />
          )}
        </PrivateRoute>
        <PrivateRoute path={match.path}>
          <SportListPage />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default SportsPage;
