import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import {
  gamesSliceName,
  gamesSliceReducer,
} from '../features/games/games.slice';
import {
  loginSliceName,
  loginSliceReducer,
} from '../features/login/login.slice';
import {
  poolsSliceName,
  poolsSliceReducer,
} from '../features/pools/pools.slice';
import {
  sportsSliceName,
  sportsSliceReducer,
} from '../features/sports/sports.slice';
import {
  teamsSliceName,
  teamsSliceReducer,
} from '../features/teams/teams.slice';
import history from './history';

const router = { router: connectRouter(history) };

const login = { [loginSliceName]: loginSliceReducer };
const teams = { [teamsSliceName]: teamsSliceReducer };
const sports = { [sportsSliceName]: sportsSliceReducer };
const pools = { [poolsSliceName]: poolsSliceReducer };
const games = { [gamesSliceName]: gamesSliceReducer };

export const rootReducer = combineReducers({
  ...router,
  ...login,
  ...teams,
  ...sports,
  ...pools,
  ...games,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    ...injectedReducers,
    ...router,
    ...login,
    ...teams,
    ...sports,
    ...pools,
    ...games,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
