export type ILanguageTexts = {
  app: Record<string, string>;
  login: Record<string, string>;
  forgotPassword: Record<string, string>;
  auth: Record<string, string>;
  homePage: Record<string, string>;
  topHeader: Record<string, string>;
  teams: Record<string, string>;
  sports: Record<string, string>;
  pools: Record<string, string>;
  games: Record<string, string>;
};

const LanguageTexts: ILanguageTexts = {
  app: {
    loading: 'Loading...',
    each: 'Each',
    maxSize: 'Max. Size',
    resolution: 'Recommended file size - ',
    resolutionSize: '500 x 300',
    maxSizeErr: 'Max allowed image size is {size}',
    mandatoryNote: '* Fields are mandatory',
    invalidCard: 'Please provide valid card details.',
    invalidDate: '$property must be a valid date',
    confirmText: 'Are you sure to do this?',
    yes: 'Yes',
    no: 'No',
    okText: 'OK',
    chooseRole: 'Choose Role',
    chooseCenter: 'Choose Center',
    chooseBatch: 'Choose Batch',
    choose: 'Choose',
    readMore: 'Read More',
    btnSubmit: 'Submit',
    notFound: 'Not Found',
    noData: 'No Data Found',
    phoneNote: 'For Ex. 5417540000',
    InvalidValue: '$property contains invalid value',
    metaTitle: 'Institute management application | Impeccable Tracker',
    metaDescription:
      'Web and mobile application software for Institute management. Manage classes, schedules, attendance, payments easily though Impeccable Tracker.',
    metaKeywords:
      'Class management, Class schedule, class management, attendee tracking, assignment review, online fees collection, sports class management, yoga class management, Music class management',
    institute: 'Institute',
    of: 'of',
    classesFor: 'classes for kids and adults',
    specializedIn: 'specialized in',
    conductingClasses: 'Currently conducting classes in',
    currencySymbol: '$',
    tablePageNumberText: 'pageIndex of length',
    tableSearchPlaceholderText: 'Type here to search...',
  },
  login: {
    emailAddressLabel: 'E-Mail Address',
    passwordLabel: 'Password',
    emailErrorText: 'Please enter your registered email address',
    passwordErrorText: 'Please enter your password',
    forgotPasswordLinkText: 'Forgot Password?',
    loginBtnText: 'Login',
    orText: 'OR',
    otherSignInText: 'Sign in using',
    noAccountText: "Don't have an account?",
    registerLinkText: 'Create Account',
  },
  forgotPassword: {
    header: 'Forgot Password?',
    emailAddressLabel: 'E-Mail Address',
    emailErrorText: 'Please enter your registered email address',
    resetLinkMsg:
      "By clicking 'Reset Password' button, we will send a password reset link",
    successMsg:
      "New password is emailed to your account. If you don't see it in your inbox it might be in your spam folder.",
    resetPasswordBtnText: 'Reset Password',
  },
  auth: {
    noaccess: 'You do not have access to this page!',
    renewtoaccess: 'Please renew your subscription to access this page!',
  },
  homePage: {
    slide1Heading1: 'SAFE',
    slide1Heading2: 'BETTING',
    slide1Text1: 'WITH 100% RISK-FREE',
    slide1Text2: 'GUARANTEE',
    slide2Heading: 'Lorem Ipsum Dolor',
    slide3Heading: 'Sequi ea ut et est quaerat',
    dummyText:
      'Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.',
    winningAmountText: 'Winning Amount',
    entryText: '${pool_entry} Entry',
    maxPlayersText: 'Max. {pool_maxPlayer} Players',
    coinsNumberText: 'Get {pool_coins} in shvrk coins',
    noGamesAvailableMsg: 'No Games Available',
    playNowBtnText: 'Play Now',
    winnersListHeading: 'Winners',
    howItWorkHeading1: "How It's",
    howItWorkHeading2: 'Work',
    contactUsHeading: 'Contact Us',
  },
  topHeader: {
    loginBtnText: 'LOGIN',
    signupBtnText: 'SIGNUP',
    currentBalanceText: 'Current Balance',
    pointsText: 'Points',
    balanceText: 'Wallet Balance',
    dropdownHeader1: 'Account',
    dropdownHeader2: 'Settings',
    dropdownDepositMenu: 'Deposit',
    dropdownMyAccountMenu: 'My Account',
    dropdownProfileMenu: 'Profile',
    dropdownCardMenu: 'Add Cards',
    dropdownLockAccountMenu: 'Lock Account',
    dropdownLogoutMenu: 'Logout',
  },
  teams: {
    'input.name': 'Team Name',
    'input.sportType': 'Sport Type',
    nameErrorText: 'Please enter team name',
    sportTypeErrorText: 'Please select sport type',
    header: 'Team Setup',
    newTeam: 'Add a Team',
    updateTeam: 'Update Team',
    deleteTeam: 'Delete Team',
    teamList: 'Team List',
    teams: 'Teams',
    team: 'Team',
    sportType: 'Sport Type',
    btnReset: 'Reset',
    btnAddMore: 'Save & Add More',
    btnSave: 'Save',
    btnDelete: 'Delete',
    createSuccessMsg: 'Team stored successfully',
    deleteSuccessMsg: 'Team deleted successfully',
  },
  sports: {
    'input.name': 'Sport Name',
    'input.icon': 'Sport Icon',
    'input.comingSoon': 'Is coming soon?',
    nameErrorText: 'Please enter sport name',
    iconErrorText: 'Please enter sport icon',
    header: 'Sport Setup',
    newSport: 'Add a Sport',
    updateSport: 'Update Sport',
    sportList: 'Sport List',
    sports: 'Sports',
    sport: 'Sport',
    icon: 'Icon',
    deleteSport: 'Delete Sport',
    btnReset: 'Reset',
    btnAddMore: 'Save & Add More',
    btnSave: 'Save',
    btnDelete: 'Delete',
    createSuccessMsg: 'Sport stored successfully',
    deleteSuccessMsg: 'Sport deleted successfully',
  },
  pools: {
    'input.name': 'Pool Name',
    'input.amount': 'Approximate Winning Amount',
    'input.entry': 'Entry Fee',
    'input.maxPlayer': 'Max Players',
    'input.coins': 'Coins',
    'input.startTime': 'Start Time',
    'input.hours': 'Hours',
    'input.commission': 'Commission (in %)',
    'input.winnersCount': 'No. Of Winners',
    'input.winnerPercent': 'Winner {{rank}} amount (in %)',
    'input.winner1': 'Winner 1 amount (in %)',
    'input.winner2': 'Winner 2 amount (in %)',
    'input.winner3': 'Winner 3 amount (in %)',
    'input.winner4': 'Winner 4 amount (in %)',
    nameErrorText: 'Please enter pool name',
    entryErrorText: 'Please enter entry fees',
    coinsErrorText: 'Please enter coins',
    amountErrorText: 'Please enter approximate winning amount',
    maxPlayerErrorText: 'Please enter maximum player',
    hoursErrorText: 'Please enter hours',
    startTimeErrorText: 'Please enter pool start time',
    header: 'Pool Setup',
    newPool: 'Add a Pool',
    updatePool: 'Update Pool',
    deletePool: 'Delete Pool',
    poolList: 'Pool List',
    pools: 'Pools',
    pool: 'Pool',
    amount: 'Approximate Winning amount',
    entry: 'Entry Fee',
    coins: 'Coins',
    maxPlayer: 'Max Player',
    btnReset: 'Reset',
    btnAddMore: 'Save & Add More',
    btnSave: 'Save',
    btnDelete: 'Delete',
    createSuccessMsg: 'Pool stored successfully',
    deleteSuccessMsg: 'Pool deleted successfully',
    confirmAlertTitle: 'Criteria',
    confirmAlertMessage:
      'If commission is 20%, then remaining 80% will be distributed among the winners',
  },
  games: {
    'input.sportType': 'Sport Type',
    'input.homeTeam': 'Home Team',
    'input.awayTeam': 'Away Team',
    'input.date': 'Date',
    'input.time': 'Time',
    'input.homeMoneyLine': 'Home Money Line',
    'input.awayMoneyLine': 'Away Money Line',
    'input.homePointSpread': 'Home Point Spread',
    'input.awayPointSpread': 'Away Point Spread',
    'input.homePointSpreadPayout': 'Home Point Spread Payout',
    'input.awayPointSpreadPayout': 'Away Point Spread Payout',
    'input.overUnder': 'Over/Under',
    'input.overPayout': 'Over Payout',
    'input.underPayout': 'Under Payout',
    'input.homeTeamScore': 'Home Team Score',
    'input.awayTeamScore': 'Away Team Score',
    sportTypeErrorText: 'Please select sport type',
    homeTeamErrorText: 'Please select home team',
    awayTeamErrorText: 'Please select away team',
    dateErrorText: 'Please select game date',
    timeErrorText: 'Please select game start time',
    homeMoneyLineErrorText: 'Please enter home team money line',
    awayMoneyLineErrorText: 'Please enter away team money line',
    homePointSpreadErrorText: 'Please enter home team point spread',
    awayPointSpreadErrorText: 'Please enter away team point spread',
    homePointSpreadPayoutErrorText:
      'Please enter home team point spread payout',
    awayPointSpreadPayoutErrorText:
      'Please enter away team point spread payout',
    overUnderErrorText: 'Please enter over under',
    underPayoutErrorText: 'Please enter under payout',
    overPayoutErrorText: 'Please enter over payout',
    header: 'Game Setup',
    newGame: 'Add a Game',
    updateGame: 'Update Game',
    bettingOptions: 'Betting Options',
    score: 'Score',
    deleteGame: 'Delete Game',
    gameList: 'Game List',
    games: 'Games',
    game: 'Game',
    homeTeam: 'Home Team',
    awayTeam: 'Away Team',
    date: 'Date',
    time: 'Time',
    status: 'Status',
    btnReset: 'Reset',
    btnAddMore: 'Save & Add More',
    btnSave: 'Save',
    btnPublish: 'Publish',
    btnDelete: 'Delete',
    createSuccessMsg: 'Game stored successfully',
    deleteSuccessMsg: 'Game deleted successfully',
    publishSuccessMsg: 'Game published successfully',
  },
};

export default LanguageTexts;
