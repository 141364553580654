/* eslint-disable jsx-a11y/label-has-associated-control */

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { CreatePoolInput, PoolModel, RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import PoolForm, { CreatePoolProps } from './PoolForm';
import { loadPool, resetLoadPool } from './pools.slice';

type PoolRecordPageParams = {
  onSubmit: (data: CreatePoolInput) => void;
  onDelete: (id: string) => void;
  errors: string[];
  loading: boolean;
};

const PoolRecordPage = ({
  onSubmit: onSubmitParent,
  onDelete: onDeleteParent,
  errors,
  loading,
}: PoolRecordPageParams): JSX.Element => {
  const { poolId } = useParams<{ poolId: string }>();
  const { pools: poolsTxt, app: appTxt } = LanguageTexts;

  const [poolLoaded, setPoolLoaded] = useState<boolean>();
  const [pools, setPools] = useState<PoolModel>();

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loadPool: {
      loading: loadPoolLoading,
      errors: loadPoolErrors,
      success: loadPoolSuccess,
    },
  } = useSelector((state: RootState) => state.pools);

  useEffect(() => {
    if (poolId && !poolLoaded) {
      dispatch(loadPool(poolId));
      setPoolLoaded(true);
    }
  }, [poolLoaded, poolId, dispatch]);

  useEffect(() => {
    if (loadPoolErrors.length > 0) {
      dispatch(resetLoadPool());
      history.push(AppRoutePath.Pools);
    } else if (loadPoolSuccess) {
      setPools(loadPoolSuccess);
      dispatch(resetLoadPool());
    }
  }, [loadPoolErrors, loadPoolSuccess, dispatch, history]);

  function onSubmit({ startTime, ...restInput }: CreatePoolProps) {
    onSubmitParent({
      ...restInput,
      startTime: startTime ? startTime.clone().utc().format('HH:mm:ss') : '',
    });
  }

  function onDelete(id: any) {
    onDeleteParent(id);
  }

  if (poolId && (!poolLoaded || loadPoolLoading || pools === undefined)) {
    return <AppLoader />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let poolInfo: CreatePoolProps | any;

  if (pools) {
    const { startTime, ...restPools } = pools;

    poolInfo = {
      ...restPools,
      startTime: moment(
        `${moment().format(moment.HTML5_FMT.DATE)}T${startTime}Z`,
      ),
    };
  }

  const poolInfoInit = poolInfo || {
    name: '',
    amount: undefined,
    coins: undefined,
    maxPlayer: undefined,
    entry: undefined,
    startTime: undefined,
    hours: undefined,
    commission: undefined,
    noOfWinners: undefined,
    winnerPercent: [],
  };

  return (
    <section>
      <h1 className="primary-heading mb-4">
        {poolId ? poolsTxt.updatePool : poolsTxt.newPool}
      </h1>

      <p className="invalid-feedback-msg text-right mb-5">
        {appTxt.mandatoryNote}
      </p>

      <div className="form">
        <PoolForm
          onSubmit={onSubmit}
          onDelete={onDelete}
          initialValues={poolInfoInit}
          errors={errors}
          loading={loading}
        />
      </div>
    </section>
  );
};

export default PoolRecordPage;
