import 'react-day-picker/lib/style.css';

import moment from 'moment';
import React, { FC } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

const todayStyle = `.DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
  color: #212529;
  font-weight: 400;
}
`;

type GameDatePickerProps = {
  value: moment.Moment | undefined;
  onChange: (newValue: moment.Moment | undefined) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabled?: boolean;
};

const GameDatePicker: FC<GameDatePickerProps> = ({
  value,
  onChange,
  disabled,
}: GameDatePickerProps): JSX.Element => {
  const today = moment().toDate();

  return (
    <>
      <style>{todayStyle}</style>
      <DayPickerInput
        value={value?.format('L') || undefined}
        onDayChange={(dayInit, { selected }) => {
          if (!selected) {
            if (!dayInit) {
              onChange(undefined);
              return;
            }
            try {
              const day = moment(dayInit);
              onChange(day);
            } catch (e) {
              onChange(undefined);
            }
          }
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        format="L"
        placeholder={moment().format('L')}
        inputProps={{
          className: `form-control ${!disabled ? 'fc-readonly' : ''}`,
          disabled,
          readOnly: true,
        }}
        dayPickerProps={{
          disabledDays: { before: new Date() },
          initialMonth: today,
        }}
      />
    </>
  );
};

GameDatePicker.defaultProps = {
  disabled: false,
};

export default GameDatePicker;
