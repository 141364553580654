import React from 'react';

import LanguageTexts from '../../common/language';

const DashboardPage: React.FC = (): JSX.Element => {
  const { app: appTxt } = LanguageTexts;
  const { homePage: homeTxt } = LanguageTexts;

  return (
    <>
      <div className="shape">
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}website/shape01-bg.png`}
          alt=""
          className="shape-1"
        />
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
          alt=""
          className="shape-2"
        />
      </div>
      <div className="breadcrumb-section bg-black">
        <div className="breadcrumb">&nbsp;</div>
      </div>
      <div className="container-fluid betgame-section">
        <div className="game-pool-lists">
          <div className="row">
            <div className="col-12">
              <h1 className="game-title text-white">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
