/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadSports } from './sports.slice';

const SportListPage = (): JSX.Element => {
  const { sports: sportsTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { sports, loading } = useSelector((state: RootState) => state.sports);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: sportsTxt.sport,
        accessor: 'name',
        Cell: ({
          row: {
            original: { _id, name, icon, comingSoon },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <Link to={`${AppRoutePath.SportsUpdate.replace(':sportId', _id)}`}>
              {name}
            </Link>
          );
        },
      },
      {
        Header: sportsTxt.icon,
        accessor: 'icon',
      },
    ],
    [sportsTxt],
  );

  const data = React.useMemo(
    () =>
      sports?.map(({ _id: sportId, ...restSport }) => {
        return {
          _id: sportId,
          ...restSport,
        };
      }),
    [sports],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadSports());
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="list-table">
      <h1 className="primary-heading mb-4">
        {sportsTxt.sports}
        <Link
          className="btn btn-success"
          style={{ float: 'right', fontSize: 18 }}
          to={AppRoutePath.SportsCreate}
        >
          {sportsTxt.newSport}
        </Link>
      </h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default SportListPage;
