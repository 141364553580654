export const MenuItems = [
  {
    path: '/app/sports',
    title: 'Sports',
    icon: '',
    roles: [],
    children: [],
  },
  {
    path: '/app/teams',
    title: 'Teams',
    icon: '',
    roles: [],
    children: [],
  },
  {
    path: '/app/pools',
    title: 'Pools',
    icon: '',
    roles: [],
    children: [],
  },
  {
    path: '/app/games',
    title: 'Games',
    icon: '',
    roles: [],
    children: [],
  },
];
