/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import ApiService from '../../services/api';
import {
  addGame,
  addGameError,
  addGameSuccess,
  cancelGame,
  cancelGameError,
  cancelGameSuccess,
  deleteGame,
  deleteGameError,
  deleteGameSuccess,
  loadGame,
  loadGameBets,
  loadGameBetsError,
  loadGameBetsSuccess,
  loadGameError,
  loadGames,
  loadGamesError,
  loadGamesSuccess,
  loadGameSuccess,
  loadSportTypes,
  loadSportTypesError,
  loadSportTypesSuccess,
  loadTeams,
  loadTeamsError,
  loadTeamsSuccess,
  publishGame,
  publishGameError,
  publishGameSuccess,
} from './games.slice';

export function* addGameAsync(action: PayloadAction<any>) {
  const { data, errors } = yield call(ApiService.addGame, action.payload);

  if (errors) {
    yield put(addGameError(errors));
  } else {
    yield put(addGameSuccess(!!data));
  }
}

export function* publishGameAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.publishGame, action.payload);

  if (!data) {
    yield put(publishGameError(['404']));
  } else {
    yield put(publishGameSuccess(data));
  }
}

export function* cancelGameAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.cancelGame, action.payload);

  if (!data) {
    yield put(cancelGameError(['404']));
  } else {
    yield put(cancelGameSuccess(data));
  }
}

export function* deleteGameAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.deleteGame, action.payload);

  if (!data) {
    yield put(deleteGameError(['404']));
  } else {
    yield put(deleteGameSuccess(data));
  }
}

export function* loadGameAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadGameFormDetails, action.payload);

  if (!data) {
    yield put(loadGameError(['404']));
  } else {
    yield put(loadGameSuccess(data));
  }
}

export function* loadGameBetsAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadGameBets, action.payload);

  if (!data) {
    yield put(loadGameBetsError(['404']));
  } else {
    yield put(loadGameBetsSuccess(data));
  }
}

export function* loadGamesAsync() {
  const { data } = yield call(ApiService.getGamesForTable);

  if (!data) {
    yield put(loadGamesError(['500']));
  } else {
    yield put(loadGamesSuccess(data));
  }
}

export function* loadSportTypesAsync() {
  const { data } = yield call(ApiService.getSportTypes);

  if (!data) {
    yield put(loadSportTypesError(['500']));
  } else {
    yield put(loadSportTypesSuccess(data));
  }
}

export function* loadTeamsAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.getTeamsForTable, action.payload);

  if (!data) {
    yield put(loadTeamsError(['500']));
  } else {
    yield put(loadTeamsSuccess(data));
  }
}

export function* watchGamesAsync() {
  yield takeEvery(addGame.toString(), addGameAsync);
  yield takeEvery(publishGame.toString(), publishGameAsync);
  yield takeEvery(cancelGame.toString(), cancelGameAsync);
  yield takeEvery(deleteGame.toString(), deleteGameAsync);
  yield takeEvery(loadGame.toString(), loadGameAsync);
  yield takeEvery(loadGames.toString(), loadGamesAsync);
  yield takeEvery(loadGameBets.toString(), loadGameBetsAsync);
  yield takeEvery(loadSportTypes.toString(), loadSportTypesAsync);
  yield takeEvery(loadTeams.toString(), loadTeamsAsync);
}
