import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import {
  CreateSportInput,
  CreateTeamInput,
  SportTypeModel,
} from '../../common/types';
import AppInputLableWithError from '../../components/AppInputLableWithError';

type SportProps = {
  onSubmit: (data: CreateSportInput) => void;
  onDelete: (id: any) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreateSportInput;
};

const SportForm: React.FC<SportProps> = ({
  onSubmit,
  onDelete,
  loading,
  errors,
  initialValues,
}: SportProps): JSX.Element => {
  const { sports: sportsTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.name) {
      errors.name = sportsTxt.nameErrorText;
    }

    if (!values.icon) {
      errors.icon = sportsTxt.iconErrorText;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };

        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ errors, values, touched }) => {
        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="name">
                    {sportsTxt['input.name']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="name" type="text" className="form-control" />
                  {errors.name && touched.name && (
                    <div className="invalid-feedback-msg">{errors.name}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="icon">
                    {sportsTxt['input.icon']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="icon" type="text" className="form-control" />
                  {errors.icon && touched.icon && (
                    <div className="invalid-feedback-msg">{errors.icon}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="custom-control custom-checkbox">
                  <Field
                    style={{ marginRight: 5 }}
                    name="comingSoon"
                    type="checkbox"
                    className="custom-control-input"
                    id="icon"
                  />
                  <label htmlFor="icon" className="custom-control-label">
                    {sportsTxt['input.comingSoon']}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {sportsTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SportForm;
