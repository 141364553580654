/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */

import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import BetStatusBadge from '../../components/BetStatusBadge';
import UserBetDisplay from '../../components/UserBetDisplay';
import { loadGame, loadGameBets } from './games.slice';

const GameBetsPage = (): JSX.Element => {
  const { gameId } = useParams<{ gameId: string }>();

  const [gameLoaded, setGameLoaded] = useState<boolean>();

  const dispatch = useDispatch();

  const {
    gameBets,
    loadGameBets: { loading: loadGameBetsLoading },
  } = useSelector((state: RootState) => state.games);

  useEffect(() => {
    if (gameId && !gameLoaded) {
      dispatch(loadGameBets(gameId));
      dispatch(loadGame(gameId));
      setGameLoaded(true);
    }
  }, [gameLoaded, gameId, dispatch]);

  const gameInfo = useMemo(() => {
    let item = null;
    if (gameBets && gameBets.length > 0) {
      const game = gameBets[0].payload.find(
        (record: any) => record.gameId === gameId,
      );
      item = game ? game.game : null;
    }
    return item;
  }, [gameId, gameBets]);

  if (gameId && (!gameLoaded || loadGameBetsLoading)) {
    return <AppLoader />;
  }

  return (
    <section>
      <h1 className="primary-heading mb-4">
        {gameInfo
          ? `${gameInfo.awayTeam.name} vs ${gameInfo.homeTeam.name}`
          : 'No Bets on this game yet.'}
      </h1>

      {gameBets && gameBets.length > 0 ? (
        <div className="card">
          <div className="card-body">
            <div className="card-header">
              <div className="row">
                <div className="col-2">User</div>
                <div className="col-3">Game</div>
                <div className="col-3">Bet</div>
                <div className="col-1">Total Wager</div>
                <div className="col-1">Odds</div>
                <div className="col-1">Potential Payout</div>
                <div className="col-1" />
              </div>
            </div>
            {gameBets.map((userBet, index) => {
              return userBet.payload.length > 1 ? (
                <div key={`accordion-l2-${index}`}>
                  <div className="card parlayCard">
                    <div
                      className="card-header"
                      style={{ backgroundColor: '#4D96F6' }}
                    >
                      <div className="row">
                        <div className="col-2">
                          {userBet.user.firstName} {userBet.user.lastName}
                        </div>
                        <div className="col-3" />
                        <div className="col-3">
                          <span className="d-block text-5 font-weight-300">
                            Parlay
                          </span>
                        </div>
                        <div className="col-1">{userBet.points}</div>
                        <div className="col-1">{userBet.winPoints}</div>
                        <div className="col-1">
                          {userBet.isWin !== null ? (
                            <div>
                              <span className="text-5 text-nowrap">
                                {userBet.isWin || userBet.isRefundable
                                  ? `+${userBet.totalPoints}`
                                  : `-${userBet.totalPoints}`}
                              </span>
                              <span className="text-5 text-uppercase" />
                            </div>
                          ) : (
                            userBet.totalPoints
                          )}
                        </div>
                        <div className="col-1">
                          <BetStatusBadge
                            isRefundable={userBet.isRefundable}
                            isWin={userBet.isWin}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" />
                    <div className="card-body">
                      {userBet.payload.map((betPayload: any, index3: any) => (
                        <div
                          className="current-game-item py-3"
                          key={`user-bet-${userBet._id}-${index3}`}
                        >
                          <div className="row">
                            <div className="col-2" />
                            <div className="col-3">
                              <span className="d-block text-5 font-weight-300">
                                {moment(betPayload.game.date).format('DD')}
                                &nbsp;
                                {moment(betPayload.game.date).format('MMM')}
                              </span>
                              <span className="d-block text-3 font-weight-400 text-muted">
                                {betPayload.teamType === 'away'
                                  ? betPayload.game.awayTeam.name
                                  : betPayload.game.homeTeam.name}
                                {betPayload.game.isCancelled ? (
                                  <span
                                    style={{ color: 'red' }}
                                    title="Cancelled"
                                  >
                                    &nbsp;X
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block text-5 font-weight-300">
                                {betPayload.betType.name}
                              </span>
                              <span className="d-block text-3 font-weight-400 text-muted">
                                <UserBetDisplay
                                  type={betPayload.betType.name}
                                  teamType={betPayload.teamType}
                                  bet={betPayload.bet}
                                />
                              </span>
                            </div>
                            <div className="col-3" />
                            <div className="col-1">
                              <BetStatusBadge
                                isRefundable={betPayload.isRefundable}
                                isWin={betPayload.isWin}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-body">
                  {userBet.payload.map((betPayload: any, index4: any) => (
                    <div
                      className="current-game-item py-3"
                      key={`user-bet-${userBet._id}-${index4}`}
                    >
                      <div className="row">
                        <div className="col-2">
                          {userBet.user.firstName} {userBet.user.lastName}
                        </div>
                        <div className="col-3">
                          <span className="d-block text-5 font-weight-300">
                            {moment(betPayload.game.date).format('DD')}
                            &nbsp;
                            {moment(betPayload.game.date).format('MMM')}
                          </span>
                          <span className="d-block text-3 font-weight-400 text-muted">
                            {betPayload.teamType === 'away'
                              ? betPayload.game.awayTeam.name
                              : betPayload.game.homeTeam.name}
                            {betPayload.game.isCancelled ? (
                              <span style={{ color: 'red' }} title="Cancelled">
                                &nbsp;X
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                        <div className="col-3">
                          <span className="d-block text-5 font-weight-300">
                            {betPayload.betType.name}
                          </span>
                          <span className="d-block text-3 font-weight-400 text-muted">
                            <UserBetDisplay
                              type={betPayload.betType.name}
                              teamType={betPayload.teamType}
                              bet={betPayload.bet}
                            />
                          </span>
                        </div>
                        <div className="col-1">{userBet.points}</div>
                        <div className="col-1">{userBet.winPoints}</div>
                        <div className="col-1">
                          {userBet.isWin !== null ? (
                            <div>
                              <span className="text-5 text-nowrap">
                                {userBet.isWin || userBet.isRefundable
                                  ? `+${userBet.totalPoints}`
                                  : `-${userBet.totalPoints}`}
                              </span>
                              <span className="text-5 text-uppercase" />
                            </div>
                          ) : (
                            userBet.totalPoints
                          )}
                        </div>
                        <div className="col-1">
                          <BetStatusBadge
                            isRefundable={betPayload.isRefundable}
                            isWin={betPayload.isWin}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default GameBetsPage;
