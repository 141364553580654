import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { MenuItems } from '../../common/menuItems';
import { RootState } from '../../common/types';
import Footer from './Footer';
import SideMenu from './SideMenu';
import TopHeader from './TopHeader';

type LayoutUserProps = {
  children: JSX.Element;
};

const LayoutAdmin: React.FC<LayoutUserProps> = ({
  children,
}: LayoutUserProps): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.login);
  const { pathname } = useLocation();
  return (
    <>
      {user ? (
        <>
          <TopHeader />
          <main id="main" className="innerpage admin-page leftsidebar-main">
            <div className="main-game-section">
              <SideMenu menuItems={MenuItems || []} pathname={pathname} />
              <section
                id="mainGameSection"
                className="main-bet-section admin-main-section bg-white px-4"
              >
                {children}
              </section>
            </div>
            <Footer />
          </main>
        </>
      ) : (
        <>
          <TopHeader />
          {children}
          <Footer />
        </>
      )}
    </>
  );
};

export default LayoutAdmin;
