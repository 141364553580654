/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import ApiService from '../../services/api';
import {
  addTeam,
  addTeamError,
  addTeamSuccess,
  deleteTeam,
  deleteTeamError,
  deleteTeamSuccess,
  loadSportTypes,
  loadSportTypesError,
  loadSportTypesSuccess,
  loadTeam,
  loadTeamError,
  loadTeams,
  loadTeamsError,
  loadTeamsSuccess,
  loadTeamSuccess,
} from './teams.slice';

export function* addTeamAsync(action: PayloadAction<any>) {
  const { data, errors } = yield call(ApiService.addTeam, action.payload);

  if (errors) {
    yield put(addTeamError(errors));
  } else {
    yield put(addTeamSuccess(!!data));
  }
}

export function* loadTeamAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadTeamFormDetails, action.payload);

  if (!data) {
    yield put(loadTeamError(['404']));
  } else {
    yield put(loadTeamSuccess(data));
  }
}

export function* loadTeamsAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.getTeamsForTable, action.payload);

  if (!data) {
    yield put(loadTeamsError(['500']));
  } else {
    yield put(loadTeamsSuccess(data));
  }
}

export function* loadSportTypesAsync() {
  const { data } = yield call(ApiService.getSportTypes);

  if (!data) {
    yield put(loadSportTypesError(['500']));
  } else {
    console.log(data);
    yield put(loadSportTypesSuccess(data));
  }
}

export function* deleteTeamAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.deleteTeam, action.payload);

  if (!data) {
    yield put(deleteTeamError(['404']));
  } else {
    yield put(deleteTeamSuccess(data));
  }
}

export function* watchTeamsAsync() {
  yield takeEvery(addTeam.toString(), addTeamAsync);
  yield takeEvery(deleteTeam.toString(), deleteTeamAsync);
  yield takeEvery(loadTeam.toString(), loadTeamAsync);
  yield takeEvery(loadTeams.toString(), loadTeamsAsync);
  yield takeEvery(loadSportTypes.toString(), loadSportTypesAsync);
}
