/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateSportInput, SportModel, SportsState } from '../../common/types';

const initialState: SportsState = {
  loading: false,
  success: null,
  errors: [],
  sports: null,
  addSport: {
    loading: false,
    success: null,
    errors: [],
  },
  loadSport: {
    loading: false,
    success: null,
    errors: [],
  },
  deleteSport: {
    loading: false,
    success: null,
    errors: [],
  },
};

const sportsSlice = createSlice({
  name: 'sports',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadSports(state, action: PayloadAction<undefined>) {
      state.loading = true;
      state.errors = [];
    },
    loadSportsSuccess(state, action: PayloadAction<SportModel[]>) {
      state.loading = false;
      state.success = !!action.payload;
      state.sports = action.payload;
    },
    loadSportsError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadSports(state) {
      state.loadSport.success = null;
      state.loadSport.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addSport(state, action: PayloadAction<CreateSportInput>) {
      state.addSport.loading = true;
      state.addSport.errors = [];
    },
    addSportSuccess(state, action: PayloadAction<boolean>) {
      state.addSport.loading = false;
      state.addSport.success = action.payload;
    },
    addSportError(state, action: PayloadAction<string[]>) {
      state.addSport.loading = false;
      state.addSport.errors = action.payload;
    },
    resetAddSportSuccess(state) {
      state.addSport.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadSport(state, action: PayloadAction<string>) {
      state.loadSport.loading = true;
      state.loadSport.errors = [];
    },
    loadSportSuccess(state, action: PayloadAction<SportModel>) {
      state.loadSport.loading = false;
      state.loadSport.success = action.payload;
    },
    loadSportError(state, action: PayloadAction<string[]>) {
      state.loadSport.loading = false;
      state.loadSport.errors = action.payload;
    },
    resetLoadSport(state) {
      state.loadSport.success = null;
      state.loadSport.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteSport(state, action: PayloadAction<string>) {
      state.deleteSport.loading = true;
      state.deleteSport.errors = [];
    },
    deleteSportSuccess(state, action: PayloadAction<boolean>) {
      state.deleteSport.loading = false;
      state.deleteSport.success = action.payload;
    },
    deleteSportError(state, action: PayloadAction<string[]>) {
      state.deleteSport.loading = false;
      state.deleteSport.errors = action.payload;
    },
    resetDeleteSportSuccess(state) {
      state.deleteSport.success = null;
    },
  },
});

export const {
  addSport,
  addSportSuccess,
  addSportError,
  resetAddSportSuccess,
  loadSport,
  loadSportSuccess,
  loadSportError,
  resetLoadSport,
  loadSports,
  loadSportsSuccess,
  loadSportsError,
  resetLoadSports,
  deleteSport,
  deleteSportSuccess,
  deleteSportError,
  resetDeleteSportSuccess,
} = sportsSlice.actions;

export const {
  name: sportsSliceName,
  reducer: sportsSliceReducer,
} = sportsSlice;
