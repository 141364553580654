/* eslint-disable no-console */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import AppLoader from '../../components/AppLoader';
import AppTable from '../../components/AppTable';
import { loadGames } from './games.slice';

const GameListPage = (): JSX.Element => {
  const { games: gamesTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { games, loading } = useSelector((state: RootState) => state.games);
  const { user: currentUser } = useSelector((state: RootState) => state.login);
  const [loadRequested, setLoadRequested] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: gamesTxt.date,
        accessor: 'date',
        Cell: ({
          row: {
            original: { _id, date },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <Link to={`${AppRoutePath.GamesUpdate.replace(':gameId', _id)}`}>
              {date}
            </Link>
          );
        },
      },
      {
        Header: gamesTxt.time,
        accessor: 'time',
      },
      {
        Header: gamesTxt.awayTeam,
        accessor: 'awayTeam.name',
      },
      {
        Header: gamesTxt.homeTeam,
        accessor: 'homeTeam.name',
      },
      {
        Header: gamesTxt.status,
        accessor: 'status',
      },
      {
        Header: 'Actions',
        accessor: '_id',
        Cell: ({
          row: {
            original: { _id },
          },
        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any) => {
          return (
            <Link to={`${AppRoutePath.GamesBets.replace(':gameId', _id)}`}>
              User Bets
            </Link>
          );
        },
      },
    ],
    [gamesTxt],
  );

  const data = React.useMemo(
    () =>
      games?.map(({ _id: gameId, date, time, ...restGame }) => {
        return {
          _id: gameId,
          date: moment(date).format('MM-DD-YYYY'),
          time: moment(date).format('hh:mm A'),
          ...restGame,
        };
      }),
    [games],
  );

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadGames());
      setLoadRequested(true);
    }
  }, [loadRequested, currentUser, dispatch]);

  if (loading || !loadRequested) {
    return <AppLoader />;
  }

  return (
    <section className="list-table">
      <h1 className="primary-heading mb-4">
        {gamesTxt.games}
        <Link
          className="btn btn-success"
          style={{ float: 'right', fontSize: 18 }}
          to={AppRoutePath.GamesCreate}
        >
          {gamesTxt.newGame}
        </Link>
      </h1>
      <AppTable columns={columns} data={data || []} />
    </section>
  );
};

export default GameListPage;
