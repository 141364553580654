/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, call } from 'redux-saga/effects';

import { watchGamesAsync } from '../features/games/games.saga';
import { watchLoginAsync } from '../features/login/login.saga';
import { watchPoolsAsync } from '../features/pools/pools.saga';
import { watchSportsAsync } from '../features/sports/sports.saga';
import { watchTeamsAsync } from '../features/teams/teams.saga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoginAsync),
    call(watchTeamsAsync),
    call(watchSportsAsync),
    call(watchPoolsAsync),
    call(watchGamesAsync),
  ]);
}
