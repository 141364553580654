/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Field, FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';

import LanguageTexts from '../../common/language';
import PoolTimePicker from './components/PoolTimePicker';

export type CreatePoolProps = {
  _id?: string;
  name: string;
  entry: number;
  maxPlayer: number;
  amount: number;
  coins: number;
  startTime: moment.Moment | undefined;
  hours: number;
  commission: number;
  noOfWinners: number;
  winnerPercent: number[];
};

type PoolProps = {
  onSubmit: (data: CreatePoolProps) => void;
  onDelete: (id: any) => void;
  loading: boolean;
  errors: string[];
  initialValues: CreatePoolProps;
};

const PoolForm: React.FC<PoolProps> = ({
  onSubmit,
  onDelete,
  loading,
  errors,
  initialValues,
}: PoolProps): JSX.Element => {
  const { app: appTxt, pools: poolsTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  // local state
  const [noOfWinners, setNoOfWinners] = useState<any>({
    value: initVals.noOfWinners,
    label: initVals.noOfWinners,
  });

  function infoMsg() {
    confirmAlert({
      customUI: ({ onClose }: any) => {
        return (
          <div className="bg-white d-flex flex-column align-items-center rounded p-4">
            <h1 className="primary-heading mb-4">
              {poolsTxt.confirmAlertTitle}
            </h1>
            <p className="text-muted">{poolsTxt.confirmAlertMessage}</p>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={onClose}
            >
              {appTxt.okText}
            </button>
          </div>
        );
      },
    });
  }

  const validate = (values: any) => {
    const errorsIn: any = {};

    if (!values.name) {
      errorsIn.name = poolsTxt.nameErrorText;
    }

    if (!values.entry) {
      errorsIn.entry = poolsTxt.entryErrorText;
    }

    if (!values.amount) {
      errorsIn.amount = poolsTxt.amountErrorText;
    }

    if (!values.coins) {
      errorsIn.coins = poolsTxt.coinsErrorText;
    }

    if (!values.maxPlayer) {
      errorsIn.maxPlayer = poolsTxt.maxPlayerErrorText;
    }

    if (!values.hours) {
      errorsIn.hours = poolsTxt.hoursErrorText;
    }

    if (!values.startTime) {
      errorsIn.startTime = poolsTxt.startTimeErrorText;
    }

    if (!values.commission && values.commission !== 0) {
      errorsIn.commission = 'Please enter commission';
    }

    if (values.commission && !(values.commission < 100)) {
      errorsIn.commission = 'Commission should be less then 100%.';
    } else if (values.commission && values.commission < 0) {
      errorsIn.commission = 'Commission should not contain negative value.';
    }

    if (!values.noOfWinners) {
      errorsIn.noOfWinners = 'Please select Number of winners.';
    }

    if (values.winnerPercent && values.winnerPercent.length > 0) {
      const negativeOrZeroVal =
        values.winnerPercent.filter((val: number) => val <= 0).length !== 0;

      if (negativeOrZeroVal) {
        errorsIn.winnerPercent = 'Winner Amount should be greater then 0.';
      } else {
        const sumOfPercent = values.winnerPercent.reduce(
          (total: number, val: number) => total + val,
          0,
        );

        if (sumOfPercent !== 100) {
          errorsIn.winnerPercent = 'Total of all Winner Amount should be 100%.';
        }
      }
    }

    return errorsIn;
  };

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        const input = { ...values };
        onSubmit(input);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, errors: errorsIn, touched }) => {
        // No of winners dropdown values
        const selOptions = Array.from({ length: values.maxPlayer }, (v, i) => {
          return { value: i + 1, label: i + 1 };
        });

        return (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="name">
                    {poolsTxt['input.name']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="name" type="text" className="form-control" />
                  {errorsIn.name && touched.name && (
                    <div className="invalid-feedback-msg">{errorsIn.name}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="entry">
                    {poolsTxt['input.entry']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="entry" type="number" className="form-control" />
                  {errorsIn.entry && touched.entry && (
                    <div className="invalid-feedback-msg">{errorsIn.entry}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="amount">
                    {poolsTxt['input.amount']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="amount" type="number" className="form-control" />
                  {errorsIn.amount && touched.amount && (
                    <div className="invalid-feedback-msg">
                      {errorsIn.amount}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="coins">
                    {poolsTxt['input.coins']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field name="coins" type="number" className="form-control" />
                  {errorsIn.coins && touched.coins && (
                    <div className="invalid-feedback-msg">{errorsIn.coins}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="startTime">
                        {poolsTxt['input.startTime']}
                        <sup className="invalid-feedback-msg">
                          &nbsp;
                          <span className="fa fa-star-of-life" />
                        </sup>
                      </label>
                      <PoolTimePicker
                        value={values.startTime}
                        disabled={false}
                        onChange={(newVal) =>
                          setFieldValue('startTime', newVal)
                        }
                      />
                      {errorsIn.startTime && touched.startTime && (
                        <div className="invalid-feedback-msg">
                          {errorsIn.startTime}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="hours">
                        {poolsTxt['input.hours']}
                        <sup className="invalid-feedback-msg">
                          &nbsp;
                          <span className="fa fa-star-of-life" />
                        </sup>
                      </label>
                      <Field
                        name="hours"
                        type="number"
                        className="form-control"
                      />
                      {errorsIn.hours && touched.hours && (
                        <div className="invalid-feedback-msg">
                          {errorsIn.hours}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="maxPlayer">
                    {poolsTxt['input.maxPlayer']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                  </label>
                  <Field
                    name="maxPlayer"
                    type="number"
                    className="form-control"
                    onChange={(e: any) => {
                      setFieldValue(
                        'maxPlayer',
                        parseInt(e.target.value, 10) || null,
                      );
                      setFieldValue('noOfWinners', null);
                      setFieldValue('winnerPercent', []);
                      setNoOfWinners({ value: undefined, label: undefined });
                    }}
                  />
                  {errorsIn.maxPlayer && touched.maxPlayer && (
                    <div className="invalid-feedback-msg">
                      {errorsIn.maxPlayer}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <label htmlFor="commission">
                    {poolsTxt['input.commission']}
                    <sup className="invalid-feedback-msg">
                      &nbsp;
                      <span className="fa fa-star-of-life" />
                    </sup>
                    <span
                      className="fas fa-question-circle text-primary ml-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() => infoMsg()}
                    />
                  </label>
                  <Field
                    name="commission"
                    type="number"
                    className="form-control"
                  />
                  {errorsIn.commission && touched.commission && (
                    <div className="invalid-feedback-msg">
                      {errorsIn.commission}
                    </div>
                  )}
                </div>
              </div>
              {values.maxPlayer > 0 ? (
                <div className="col-lg-6 col-12">
                  <div className="form-group">
                    <label htmlFor="winnersCount">
                      {poolsTxt['input.winnersCount']}
                      <sup className="invalid-feedback-msg">
                        &nbsp;
                        <span className="fa fa-star-of-life" />
                      </sup>
                    </label>
                    <Select
                      value={noOfWinners}
                      options={selOptions}
                      onChange={(valObj) => {
                        setNoOfWinners(valObj);
                        setFieldValue('noOfWinners', valObj.value);
                        setFieldValue(
                          'winnerPercent',
                          Array.from({ length: valObj.value }),
                        );
                      }}
                    />
                    {errorsIn.noOfWinners && touched.noOfWinners && (
                      <div className="invalid-feedback-msg">
                        {errorsIn.noOfWinners}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <FieldArray
                name="winnerPercent"
                render={() =>
                  values.winnerPercent
                    ? values.winnerPercent.map((val, index) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={`winnerPercent${index}`}
                          className="col-lg-3 col-12"
                        >
                          <div className="form-group">
                            <label htmlFor={`winnerPercent.${index}`}>
                              {poolsTxt['input.winnerPercent'].replace(
                                '{{rank}}',
                                `${index + 1}`,
                              )}
                              <sup className="invalid-feedback-msg">
                                &nbsp;
                                <span className="fa fa-star-of-life" />
                              </sup>
                            </label>
                            <Field
                              name={`winnerPercent.${index}`}
                              type="number"
                              className="form-control"
                            />
                          </div>
                        </div>
                      ))
                    : []
                }
              />
            </div>
            {errorsIn.winnerPercent && touched.winnerPercent && (
              <div className="invalid-feedback-msg">
                {errorsIn.winnerPercent}
              </div>
            )}
            <div className="row">
              <div className="col-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {poolsTxt.btnSave}
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PoolForm;
